import { useCallback, useState } from 'react';
import '../index.css'
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { useMediaQuery } from 'react-responsive'
export default function RegistroSiniestralidad({ expediente, otrosExp, otrosExpReq, otrosExpReqCliente ,otrosExpReqReq }) {
    const [cookies, setCookie, removeCookie] = useCookies(['user']);
    const [isChecked, setIsChecked] = useState(expediente.vistoRegistroSiniestralidad);
    const actualizarVistoRegistroSiniestralidad = useCallback(async (e) => {
        const response = await axios.post(`https://api.tuveunchoque.com.ar/api/gerencia/actualizarVistoRegistroSiniestralidad`, {
            id: expediente.id
        }).then(async (response) => {
            if (response.status === 200) {
                setTimeout(async () => {
                    window.location.reload()
                }, 1000);
            }
        })
    }, [expediente]);
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            actualizarVistoRegistroSiniestralidad();
        }
    };
    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    const isMobile375 = useMediaQuery({ query: '(max-width: 375px)' });
    const isMobile412 = useMediaQuery({ query: '(max-width: 412px)' });
    const isMobile480 = useMediaQuery({ query: '(max-width: 480px)' });
    
    return (
        <>
            {isMobile480 ?
                <div className='sectionAmpliaciones375 mb-5'>
                    <div className="divTituloAmpliaciones375 d-flex justify-content-between align-items-center pe-3 ps-3">
                        <h3 className='registroSiniestralidadTitulo375 m-0'>REGISTRO DE SINIESTRALIDAD</h3>
                        <div className='d-flex '>
                            <h3 className='registroSiniestralidadTitulo375 m-0 pe-1'>- LEÍDO</h3>
                            <div className='center'>
                                <input
                                    type="checkbox"
                                    checked={isChecked}
                                    name=""
                                    id=""
                                    className='form-check-input m-0 ps-1'
                                    onKeyDown={handleKeyDown}
                                    onChange={handleCheckboxChange}
                                    disabled={expediente.vistoRegistroSiniestralidad === 1 ? true : false}
                                />
                            </div>
                        </div>
                    </div>
                    <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
                        <table className="table">
                            <thead className='registroSiniestralidadHeaderMovil'>
                                <tr>
                                    <th>Nro</th>
                                    <th>Patente</th>
                                    <th>F. Stro</th>
                                    <th>Nombre</th>
                                    <th>Estado</th>
                                    <th>Patente Requerido</th>
                                </tr>
                            </thead>
                            <tbody className='registroSiniestralidadHeaderMovil'>
                                {otrosExp.filter(e => e.id !== expediente.id).map((e) => (
                                    <tr key={e.id}>
                                        <th scope="row">
                                            <a href={`https://sistema.tuveunchoque.com.ar/#/expediente/${e.nro}`} target="_blank" rel="noopener noreferrer">{e.nro}</a>
                                        </th>
                                        <td className="bg-primary bg-opacity-25">{e.dominioCliente}</td>
                                        <td>{e.fechaStro}</td>
                                        <td>{e.nombre}</td>
                                        <td>{e.estado}</td>
                                        <td>{e.patenteRequerido}</td>
                                    </tr>
                                ))}
                                {otrosExpReq.filter(e => e.id !== expediente.id).map((e) => (
                                    <tr key={e.id}>
                                        <th scope="row">
                                            <a href={`https://sistema.tuveunchoque.com.ar/#/expediente/${e.nro}`} target="_blank" rel="noopener noreferrer">{e.nro}</a>
                                        </th>
                                        <td>{e.dominioCliente}</td>
                                        <td>{e.fechaStro}</td>
                                        <td>{e.nombre}</td>
                                        <td>{e.estado}</td>
                                        <td className="bg-primary bg-opacity-25">{e.patenteRequerido}</td>
                                    </tr>
                                ))}
                                {otrosExpReqCliente.filter(e => e.id !== expediente.id).map((e) => (
                                    <tr key={e.id}>
                                        <th scope="row">
                                            <a href={`https://sistema.tuveunchoque.com.ar/#/expediente/${e.nro}`} target="_blank" rel="noopener noreferrer">{e.nro}</a>
                                        </th>
                                        <td style={{backgroundColor: '#F99946'}}>{e.dominioCliente}</td>
                                        <td>{e.fechaStro}</td>
                                        <td>{e.nombre}</td>
                                        <td>{e.estado}</td>
                                        <td>{e.patenteRequerido}</td>
                                    </tr>
                                ))}
                                {otrosExpReqReq.filter(e => e.id !== expediente.id).map((e) => (
                                    <tr key={e.id}>
                                        <th scope="row">
                                            <a href={`https://sistema.tuveunchoque.com.ar/#/expediente/${e.nro}`} target="_blank" rel="noopener noreferrer">{e.nro}</a>
                                        </th>
                                        <td >{e.dominioCliente}</td>
                                        <td>{e.fechaStro}</td>
                                        <td>{e.nombre}</td>
                                        <td>{e.estado}</td>
                                        <td style={{backgroundColor: '#F99946'}}>{e.patenteRequerido}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                :
                <div className='sectionAmpliaciones mb-5'>
                    <div className="divTituloAmpliaciones d-flex justify-content-between align-items-center pe-5 ps-5">
                        <h3 className='registroSiniestralidadTitulo m-0'>REGISTRO DE SINIESTRALIDAD</h3>
                        <div className='d-flex '>
                            <h3 className='registroSiniestralidadTitulo m-0 pe-1'>- LEÍDO</h3>
                            <div className='center'>
                                <input
                                    type="checkbox"
                                    checked={isChecked}
                                    name=""
                                    id=""
                                    className='form-check-input m-0 ps-1'
                                    onKeyDown={handleKeyDown}
                                    onChange={handleCheckboxChange}
                                    disabled={expediente.vistoRegistroSiniestralidad === 1 ? true : false}
                                />

                            </div>
                        </div>
                    </div>
                    <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Nro</th>
                                    <th>Patente</th>
                                    <th>F. Stro</th>
                                    <th>Cia</th>
                                    <th>Nombre</th>
                                    <th>Estado</th>
                                    <th>Patente Requerido</th>
                                </tr>
                            </thead>
                            <tbody>
                                {otrosExp.filter(e => e.id !== expediente.id).map((e) => (
                                    <tr key={e.id}>
                                        <th scope="row">
                                            <a href={`https://sistema.tuveunchoque.com.ar/#/expediente/${e.nro}`} target="_blank" rel="noopener noreferrer">{e.nro}</a>
                                        </th>
                                        <td className="bg-primary bg-opacity-25">{e.dominioCliente}</td>
                                        <td>{e.fechaStro}</td>
                                        <td>{e.compania}</td>
                                        <td>{e.nombre}</td>
                                        <td>{e.estado}</td>
                                        <td>{e.patenteRequerido}</td>
                                    </tr>
                                ))}
                                {otrosExpReq.filter(e => e.id !== expediente.id).map((e) => (
                                    <tr key={e.id}>
                                        <th scope="row">
                                            <a href={`https://sistema.tuveunchoque.com.ar/#/expediente/${e.nro}`} target="_blank" rel="noopener noreferrer">{e.nro}</a>
                                        </th>
                                        <td>{e.dominioCliente}</td>
                                        <td>{e.fechaStro}</td>
                                        <td>{e.compania}</td>
                                        <td>{e.nombre}</td>
                                        <td>{e.estado}</td>
                                        <td className="bg-primary bg-opacity-25">{e.patenteRequerido}</td>
                                    </tr>
                                ))}
                                {otrosExpReqCliente.filter(e => e.id !== expediente.id).map((e) => (
                                    <tr key={e.id}>
                                        <th scope="row">
                                            <a href={`https://sistema.tuveunchoque.com.ar/#/expediente/${e.nro}`} target="_blank" rel="noopener noreferrer">{e.nro}</a>
                                        </th>
                                        <td style={{backgroundColor: '#F99946'}}>{e.dominioCliente}</td>
                                        <td>{e.fechaStro}</td>
                                        <td>{e.compania}</td>
                                        <td>{e.nombre}</td>
                                        <td>{e.estado}</td>
                                        <td>{e.patenteRequerido}</td>
                                    </tr>
                                ))}
                                {otrosExpReqReq.filter(e => e.id !== expediente.id).map((e) => (
                                    <tr key={e.id}>
                                        <th scope="row">
                                            <a href={`https://sistema.tuveunchoque.com.ar/#/expediente/${e.nro}`} target="_blank" rel="noopener noreferrer">{e.nro}</a>
                                        </th>
                                        <td >{e.dominioCliente}</td>
                                        <td>{e.fechaStro}</td>
                                        <td>{e.compania}</td>
                                        <td>{e.nombre}</td>
                                        <td>{e.estado}</td>
                                        <td style={{backgroundColor: '#F99946'}}>{e.patenteRequerido}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>}
        </>
    )
}