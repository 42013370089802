import { useMediaQuery } from "react-responsive";
import "./expedienteCliente.css";
import banner from "../images/bannerLoginCliente.jpeg";
import PlanillasCliente from "../components/ExpedienteCliente/PlanillasCliente";
import logo from "../images/logoTuveUnChoque.png";
import { useState } from "react";
export default function ExpedienteCliente() {
    const isMobile375 = useMediaQuery({ query: "(max-width: 375px)" });
    const isMobile412 = useMediaQuery({
        query: "(min-width: 376px) and (max-width: 412px)",
    });
    const isMobile480 = useMediaQuery({
        query: "(min-width: 413px) and (max-width: 480px)",
    });
    const expediente = JSON.parse(localStorage.getItem("expCliente"));
    const formatMesAnio = (fecha) => {
        const meses = [
          "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
          "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
        ];
        const [anio, mes] = fecha.split("-");
        return `${meses[parseInt(mes, 10) - 1]} ${anio}`;
      };
    return (
        <>
            {isMobile375 ? (
                <main className="mainLoginClienteMovil">
                    <div className="center" style={{ height: "8vh", marginTop: "3vh" }}>
                        <img src={logo} alt="" width={"50%"} height={"100%"} />
                    </div>
                    <section className="" style={{ marginTop: "5vh" }}>
                        <div className="expNroClienteMovilTexto375">Expediente Nro</div>
                        <div className="expNroClienteMovil">{expediente.nro}</div>
                        <div className="infoFechasClienteContenedorMovil" style={{ marginTop: "2vh" }}>
                            <div class="">
                                <p className="m-0 subtituloTituloExpCliente375">F. Expediente</p>
                                <p className="textoSubtituloExp375">{expediente.fechaExp}</p>
                            </div>
                            <div class="">
                                <p className="m-0 subtituloTituloExpCliente375">Patente</p>
                                <p className="textoSubtituloExp375">{expediente.dominioCliente}</p>
                            </div>
                            <div class="">
                                <p className="m-0 subtituloTituloExpCliente375">F. Siniestro</p>
                                <p className="textoSubtituloExp375">{expediente.fechaStro}</p>
                            </div>
                        </div>
                        <div className="estadoClienteMovil375" style={{ marginTop: "2vh" }}>ESTADO:{" "}<strong className="ms-2" style={{ fontWeight: "bold" }}>{expediente.estado}</strong></div>
                        <div className="infoFechasClienteContenedorMovil" style={{ marginTop: "2vh" }}>
                            <div class="">
                                <p className="m-0 subtituloTituloExpCliente375">{expediente.mesCobrado ? 'Mes Cobrado' : 'Mes Estimado'}</p>
                                <p className="textoSubtituloExp375">{expediente.mesCobrado ? formatMesAnio(expediente.mesCobrado) : (expediente.mesEstimado ? formatMesAnio(expediente.mesEstimado) : null)}</p>
                            </div>
                        </div>
                        <div className="textoExplicacionClienteMovil" style={{ marginTop: "2vh" }}>{expediente?.estadoTexto?.descripcionCliente}</div>
                        <div style={{ marginTop: "2vh" }}></div>
                        <PlanillasCliente expediente={expediente} />
                    </section>
                </main>
            ) : isMobile412 ? (
                <main className="mainLoginClienteMovil">
                    <div className="center" style={{ height: "8vh", marginTop: "3vh" }}>
                        <img src={logo} alt="" width={"50%"} height={"100%"} />
                    </div>
                    <section className="" style={{ marginTop: "5vh" }}>
                        <div className="expNroClienteMovilTexto375">Expediente Nro</div>
                        <div className="expNroClienteMovil">{expediente.nro}</div>
                        <div className="infoFechasClienteContenedorMovil" style={{ marginTop: "2vh" }}>
                            <div class="">
                                <p className="m-0 subtituloTituloExpCliente375">F. Expediente</p>
                                <p className="textoSubtituloExp375">{expediente.fechaExp}</p>
                            </div>
                            <div class="">
                                <p className="m-0 subtituloTituloExpCliente375">Patente</p>
                                <p className="textoSubtituloExp375">{expediente.dominioCliente}</p>
                            </div>
                            <div class="">
                                <p className="m-0 subtituloTituloExpCliente375">F. Siniestro</p>
                                <p className="textoSubtituloExp375">{expediente.fechaStro}</p>
                            </div>
                        </div>
                        <div className="estadoClienteMovil375" style={{ marginTop: "2vh" }}>ESTADO:{" "}<strong className="ms-2" style={{ fontWeight: "bold" }}>{expediente.estado}</strong></div>
                        <div className="infoFechasClienteContenedorMovil" style={{ marginTop: "2vh" }}>
                            <div class="">
                                <p className="m-0 subtituloTituloExpCliente375">{expediente.mesCobrado ? 'Mes Cobrado' : 'Mes Estimado'}</p>
                                <p className="textoSubtituloExp375">{expediente.mesCobrado ? formatMesAnio(expediente.mesCobrado) : (expediente.mesEstimado ? formatMesAnio(expediente.mesEstimado) : null)}</p>
                            </div>
                        </div>
                        <div className="textoExplicacionClienteMovil" style={{ marginTop: "2vh" }}>{expediente?.estadoTexto?.descripcionCliente}</div>
                        <div style={{ marginTop: "2vh" }}></div>
                        <PlanillasCliente expediente={expediente} />
                    </section>
                </main>
            ) : isMobile480 ? (
                <main className="mainLoginClienteMovil">
                    <div className="center" style={{ height: "8vh", marginTop: "3vh" }}>
                        <img src={logo} alt="" width={"50%"} height={"100%"} />
                    </div>
                    <section className="" style={{ marginTop: "5vh" }}>
                        <div className="expNroClienteMovilTexto">Expediente Nro</div>
                        <div className="expNroClienteMovil">{expediente.nro}</div>
                        <div className="infoFechasClienteContenedorMovil" style={{ marginTop: "2vh" }}>
                            <div class="">
                                <p className="m-0 subtituloTituloExpCliente">F. Expediente</p>
                                <p className="textoSubtituloExp">{expediente.fechaExp}</p>
                            </div>
                            <div class="">
                                <p className="m-0 subtituloTituloExpCliente">Patente</p>
                                <p className="textoSubtituloExp">{expediente.dominioCliente}</p>
                            </div>
                            <div class="">
                                <p className="m-0 subtituloTituloExpCliente">F. Siniestro</p>
                                <p className="textoSubtituloExp">{expediente.fechaStro}</p>
                            </div>
                        </div>
                        <div className="estadoClienteMovil" style={{ marginTop: "2vh" }}>ESTADO:{" "}<strong className="ms-2" style={{ fontWeight: "bold" }}>{expediente.estado}</strong></div>
                        <div className="infoFechasClienteContenedorMovil" style={{ marginTop: "2vh" }}>
                            <div class="">
                                <p className="m-0 subtituloTituloExpCliente">{expediente.mesCobrado ? 'Mes Cobrado' : 'Mes Estimado'}</p>
                                <p className="textoSubtituloExp">{expediente.mesCobrado ? formatMesAnio(expediente.mesCobrado) : (expediente.mesEstimado ? formatMesAnio(expediente.mesEstimado) : null)}</p>
                            </div>
                        </div>
                        <div className="textoExplicacionClienteMovil" style={{ marginTop: "2vh" }}>{expediente?.estadoTexto?.descripcionCliente}</div>
                        <div style={{ marginTop: "2vh" }}></div>
                        <PlanillasCliente expediente={expediente} />
                    </section>
                </main>
            ) : (
                <main className="mainLoginCliente">
                    <section className="fotoMainClienteLadoIzqContenedor">
                        <img src={banner} alt="" className="fotoMainClienteLadoIzq" />
                    </section>
                    <section className="">
                        <div className="center" style={{ height: "8vh", marginTop: "3vh" }}>
                            <img src={logo} alt="" width={"25%"} height={"100%"} />
                        </div>
                        <div className="expNroClienteMovilTexto" style={{ marginTop: "5vh" }}>Expediente Nro</div>
                        <div className="expNroClienteMovil">{expediente.nro}</div>
                        <div className="infoFechasClienteContenedorMovil" style={{ marginTop: "2vh" }}>
                            <div class="">
                                <p className="m-0 subtituloTituloExpCliente">F. Expediente</p>
                                <p className="textoSubtituloExp">{expediente.fechaExp}</p>
                            </div>
                            <div class="">
                                <p className="m-0 subtituloTituloExpCliente">Patente</p>
                                <p className="textoSubtituloExp">{expediente.dominioCliente}</p>
                            </div>
                            <div class="">
                                <p className="m-0 subtituloTituloExpCliente">F. Siniestro</p>
                                <p className="textoSubtituloExp">{expediente.fechaStro}</p>
                            </div>
                            <div class="">
                                <p className="m-0 subtituloTituloExpCliente">{expediente.mesCobrado ? 'Mes Cobrado' : 'Mes Estimado'}</p>
                                <p className="textoSubtituloExp">{expediente.mesCobrado ? formatMesAnio(expediente.mesCobrado) : (expediente.mesEstimado ? formatMesAnio(expediente.mesEstimado) : null)}</p>
                            </div>
                        </div>
                        <div className="estadoClienteMovil" style={{marginTop: '2vh'}}>ESTADO:{" "}<strong className="ms-2" style={{ fontWeight: "bold" }}>{expediente.estado}</strong></div>
                        <div className="textoExplicacionCliente" style={{marginTop: '2vh'}}>{expediente?.estadoTexto?.descripcionCliente}</div>
                        <PlanillasCliente expediente={expediente} />
                    </section>
                </main>
            )}
        </>
    );
}
