import { useState } from "react";
import * as XLSX from "xlsx";

export default function ExcelResumen({ data, fields }) {
    const [loading, setLoading] = useState(false);

    const formatDate = (dateStr) => {
        const [year, month, day] = dateStr.split("-");
        return `${day}-${month}-${year}`;
    };

    const handleDownload = () => {
        setLoading(true);

        const filterData = (data, fields) => {
            // Definir el orden de los campos prioritarios
            const priorityFields = [
                "nro",
                "fechaExp",
                "fechaVencimiento",
                "fechaCambioEstado",
                "ultimaMod"
            ];

            return data.map(item => {
                let filteredItem = {};

                // Asignar primero los campos prioritarios
                priorityFields.forEach(field => {
                    if (item.hasOwnProperty(field)) {
                        if (['fechaCambioEstado'].includes(field) && item[field]) {
                            filteredItem[field] = formatDate(item[field]);
                        } else {
                            filteredItem[field] = item[field];
                        }
                    }
                });

                // Asignar el resto de los campos que no están en priorityFields
                fields.forEach(field => {
                    if (!priorityFields.includes(field) && item.hasOwnProperty(field)) {
                        filteredItem[field] = item[field];
                    }
                });

                return filteredItem;
            });
        };

        const filteredData = filterData(data, fields);
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(filteredData);
        XLSX.utils.book_append_sheet(workbook, worksheet, "Expedientes");

        setTimeout(() => {
            XLSX.writeFile(workbook, "data.xlsx");
            setLoading(false);
        }, 1000);
    };

    return (
        <>
            <button className="btn btn-primary ms-3" onClick={handleDownload} disabled={loading}>
                {loading ? "Cargando..." : "Revisión"}
            </button>
        </>
    );
}
