import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import { useMediaQuery } from 'react-responsive'
import "react-circular-progressbar/dist/styles.css";
export default function Nivel({ nivel }) {
    let color = 'transparent'
    let porcentaje = '0'
    if (Number(nivel) === 1) {
        color = '#4CAF50'
        porcentaje = 100
    } else if (Number(nivel) === 2) {
        color = '#FFC107'
        porcentaje = 100
    } else if (Number(nivel) === 3) {
        color = '#F44336'
        porcentaje = 100
    } else {
        porcentaje = 100
        color = 'white'
    }
    const style = { height: '22px', width: '22px', boxShadow: '0 1px 1px rgba(0, 0, 0, 0.2)', borderRadius: '50%', }

    const isMobile375 = useMediaQuery({ query: '(max-width: 375px)' });
    const isMobile412 = useMediaQuery({ query: '(min-width: 376px) and (max-width: 412px)' });
    const isMobile480 = useMediaQuery({ query: '(min-width: 413px) and (max-width: 480px)' });
    const isPC = useMediaQuery({ query: '(min-width: 480px) and (max-width: 1920px)' });

    const div375 = { width: '16px', height: '16px', borderRadius: '50%', backgroundColor: color }
    const div412 = { width: '16px', height: '16px', borderRadius: '50%', backgroundColor: color }
    const div480 = { width: '16px', height: '16px', borderRadius: '50%', backgroundColor: color }
    const divPc = { width: '20px', height: '20px', borderRadius: '50%', backgroundColor: color }
    const divPcBig = { width: '27px', height: '27px', borderRadius: '50%', backgroundColor: color }
    return (
        <>
            {isMobile375 && (
                <div className='me-2' style={div375}></div>
            )}

            {isMobile412 && (
               <div className='me-2' style={div412}></div>
            )}

            {isMobile480 && (
                <div className='me-2' style={div480}></div>
            )}
            {isPC && (
                <div className='me-2' style={divPc}></div>
            )}
            {!isMobile375 && !isMobile412 && !isMobile480 && !isPC && (
                <div className='me-2' style={divPcBig}></div>
            )}
        </>
    )
}