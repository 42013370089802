import axios from "axios";
import { useState, useEffect } from "react";
import Expediente from "../components/Expedientes/Expediente";
import "./index.css";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";
import { MultiSelect } from "react-multi-select-component";
import ReactPaginate from 'react-paginate';
import Excel from "./Excel";
import './paginate.css'
import iconoFiltrar from "../images/iconoFiltrar.png";
import iconoGlobal from "../images/iconoGlobal.png";
import Select from 'react-select';
import { useMediaQuery } from 'react-responsive'
import ExpedienteTablaMovil from "../components/TablaMovil/ExpedienteTablaMovil";
export default function ExpedientesMediador() {
    const navigate = useNavigate();
    const a = localStorage.getItem('user');
    if (a === null) {
        navigate('/login')
    }
    const [expedientes, setExpedientes] = useState([]);
    const [productores, setProductores] = useState([]);
    const [companias, setCompanias] = useState([]);
    const [preCarga, setPreCarga] = useState(false)
    useEffect(() => {
        axios.get("https://api.tuveunchoque.com.ar/api/gerencia/expedientes/tablas").then((res) => {
            setExpedientes(res.data.reverse());
            setPreCarga(true)
        });
        axios.get("https://api.tuveunchoque.com.ar/api/nuevosUsuarios/pasParaFiltros").then((res) => {
            setProductores(res.data);
        });
        axios.get("https://api.tuveunchoque.com.ar/api/gerencia/companias").then((res) => {
            setCompanias(res.data);
        });
    }, []);
    const estadoInput = [
        { label: "RECEPCIONADO", value: "RECEPCIONADO" },
        { label: "SIN DENUNCIA", value: "SIN DENUNCIA" },
        { label: "ARMADO PRELIMINAR", value: "ARMADO PRELIMINAR" },
        { label: "RECLAMO PRESENTADO", value: "RECLAMO PRESENTADO" },
        { label: "ACEPTACION", value: "ACEPTACION" },
        { label: "EN PROCESO DE PAGO", value: "EN PROCESO DE PAGO" },
        { label: "COBRADO", value: "COBRADO" },
        { label: "MEDIACION", value: "MEDIACION" },
        { label: "CERRADO", value: "CERRADO" },
    ];
    const nivelInput = [
        { label: "0", value: "0" },
        { label: "1", value: "1" },
        { label: "2", value: "2" },
        { label: "3", value: "3" },
    ];
    const fechaInput = [
        { label: "HOY", value: "HOY" },
        { label: "MAÑANA", value: "MAÑANA" },
        { label: "VENCIDOS", value: "VENCIDOS" },
    ];
    const asignadoInput = [
        { label: "AGOSTINA", value: "AGOSTINA" },
        { label: "CATALINA", value: "CATALINA" },
        { label: "DANIELA", value: "DANIELA" },
        { label: "FRANCO", value: "FRANCO" },
        { label: "JUAN", value: "JUAN" },
        { label: "SOFIA", value: "SOFIA" },
        { label: "MICAELA", value: 'MICAELA' },
        { label: "FEDERICO", value: "FEDERICO" },
        { label: "FACUNDO", value: 'FACUNDO' },
        { label: "LUCAS", value: 'LUCAS' },
        { label: "GONZALO", value: 'GONZALO' },
        { label: "", value: null }
    ];
    const compInput = companias.map((c) => {
        return { value: c.compania, label: c.compania };
    });
    const proInput = productores.map((p) => {
        return { value: p.nombre, label: p.nombre };
    });
    let updatedList = expedientes;
    const [cookies, setCookie, removeCookie] = useCookies(["user"]);
    const cook = () => {
        if (!cookies.user) {
            navigate("/login");
        }
    };
    cook();
    const [infoTabla, setInfoTabla, removeInfoTabla] = useCookies(["tabla"]);
    const [info, setInfo] = useState({
        info: infoTabla.tabla.info,
        pas: infoTabla.tabla.pas,
        comp: infoTabla.tabla.comp,
        estado: infoTabla.tabla.estado,
        nivel: infoTabla.tabla.nivel,
        fecha: infoTabla.tabla.fecha,
        asignado: infoTabla.tabla.asignado,
        estadoPrueba: infoTabla.tabla.estadoPrueba,
        patente: infoTabla.tabla.patente,
        cliente: infoTabla.tabla.cliente,
    });
    const conFiltro = () => {
        if (infoTabla.tabla.info) {
            updatedList = updatedList.filter((expediente) =>
                JSON.stringify(expediente.nro).includes(infoTabla.tabla.info) ||
                JSON.stringify(expediente.polizaRequerido).replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '').includes(infoTabla.tabla.info) ||
                JSON.stringify(expediente.nroReclamoCompaniaRequerido).replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '').includes(infoTabla.tabla.info) ||
                JSON.stringify(expediente.polizaRequerido).replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '').substring(-4).includes(infoTabla.tabla.info) ||
                JSON.stringify(expediente.nroReclamoCompaniaRequerido).replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '').substring(-4).includes(infoTabla.tabla.info) ||
                JSON.stringify(expediente.dominioCliente).toLowerCase().includes(JSON.stringify(infoTabla.tabla.info).toLowerCase().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')) ||
                JSON.stringify(expediente.productorAsociado).toLowerCase().includes(JSON.stringify(infoTabla.tabla.info).toLowerCase().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')) ||
                JSON.stringify(expediente.nombre).toLowerCase().includes(JSON.stringify(infoTabla.tabla.info).toLowerCase().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')) ||
                JSON.stringify(expediente.compania).toLowerCase().includes(JSON.stringify(infoTabla.tabla.info).toLowerCase().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')) ||
                JSON.stringify(expediente.patenteRequerido).toLowerCase().includes(JSON.stringify(infoTabla.tabla.info).toLowerCase().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')) ||
                JSON.stringify(expediente.nroSeguimientoCompaniaRequerido).toLowerCase().includes(JSON.stringify(infoTabla.tabla.info).toLowerCase().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''))
            );
        }
        if (infoTabla.tabla.pas.length > 0) {
            updatedList = updatedList.filter((expediente) => {
                return infoTabla.tabla.pas.some((ele) => {
                    return ele.value === expediente.productorAsociado;
                });
            });
        }
        if (infoTabla.tabla.comp.length > 0) {
            updatedList = updatedList.filter((expediente) => {
                return infoTabla.tabla.comp.some((ele) => {
                    return ele.value === expediente.compania;
                });
            });
        }
        if (infoTabla.tabla.estado.length > 0) {
            updatedList = updatedList.filter((expediente) => {
                return infoTabla.tabla.estado.some((ele) => {
                    return ele.value === expediente.estado;
                });
            });
        }
        if (infoTabla.tabla.asignado.length > 0) {
            updatedList = updatedList.filter((expediente) => {
                return infoTabla.tabla.asignado.some((ele) => {
                    return ele.value === expediente.asignadoA;
                });
            });
        }
        if (infoTabla.tabla.nivel.length > 0) {
            updatedList = updatedList.filter((expediente) => {
                return infoTabla.tabla.nivel.some((ele) => {
                    return ele.value == expediente.nivel;
                });
            });
        }
        if (infoTabla.tabla.fecha.some((ele) => { return ele.value === "HOY"; })) {
            updatedList = updatedList.filter((e) => new Date(moment(e.fechaVencimiento, "DD/MM/YYYY").toDate()).toDateString() === new Date().toDateString());
        }
        if (infoTabla.tabla.fecha.some((ele) => { return ele.value === "MAÑANA"; })) {
            updatedList = updatedList.filter((e) => new Date(moment(e.fechaVencimiento, "DD/MM/YYYY").toDate()).toLocaleDateString() === new Date(+new Date().setHours(0, 0, 0, 0) + 86400000).toLocaleDateString());
        }
        if (infoTabla.tabla.fecha.some((ele) => { return ele.value === "VENCIDOS" })) {
            updatedList = updatedList.filter((e) => e.estado !== 'COBRADO' && e.estado !== 'CERRADO' && Date.parse(moment(e.fechaVencimiento, "DD/MM/YYYY").toDate()) < new Date().setHours(0, 0, 0, 0));
        }
        if (cookies.user.cargo === "MEDIADOR") {
            updatedList = updatedList.filter(
                (expediente) => expediente.estado === 'MEDIACION'
            );
        }
    };
    conFiltro();
    const [abc, setAbc] = useState(20);
    const [bcd, setBcd] = useState(20);
    useEffect(() => {
        setCookie('tabla', JSON.stringify(info), { path: '/' });
    }, [info]);
    const filtrar = () => {
        setAbc(20);
        setBcd(20);
    };
    const vaciar = () => {
        setAbc(20);
        setBcd(20);
        setInfo({
            info: "",
            pas: [],
            comp: [],
            estado: [],
            nivel: [],
            fecha: [],
            asignado: [],
            patente: "",
        });
    };
    const btn = { border: 'none', background: 'none', fontSize: '12px', color: '#0089FF', fontWeight: '100' }
    const [sortA, setSortA] = useState({ sort: 'NRO ↑' })
    const sort = () => {
        if (sortA.sort === 'NRO ↑') {
            updatedList.sort(function (a, b) {
                return b.nro - a.nro;
            })
        }
        if (sortA.sort === 'NRO ↓') {
            updatedList.sort(function (a, b) {
                return a.nro - b.nro;
            })
        }
        if (sortA.sort === 'NIVEL ↑') {
            updatedList.sort(function (a, b) {
                return a.nivel - b.nivel;
            })
        }
        if (sortA.sort === 'NIVEL ↓') {
            updatedList.sort(function (a, b) {
                return b.nivel - a.nivel;
            })
        }
        if (sortA.sort === 'PATENTE ↑') {
            updatedList.sort(function (a, b) {
                { if (a.dominioCliente && b.dominioCliente) return JSON.stringify(a.dominioCliente).localeCompare(JSON.stringify(b.dominioCliente)) }
            })
        }
        if (sortA.sort === 'PATENTE ↓ZA') {
            updatedList.sort(function (a, b) {
                { if (a.dominioCliente && b.dominioCliente) return JSON.stringify(b.dominioCliente).localeCompare(JSON.stringify(a.dominioCliente)) }
            })
        }
        if (sortA.sort === 'PAS ↑') {
            updatedList.sort(function (a, b) {
                const nameA = JSON.stringify(a.productorAsociado).toLowerCase()
                const nameB = JSON.stringify(b.productorAsociado).toLowerCase()
                if (nameA < nameB)
                    return -1;
                if (nameA > nameB)
                    return 1;
            })
        }
        if (sortA.sort === 'PAS ↓ZA') {
            updatedList.sort(function (a, b) {
                const nameA = JSON.stringify(a.productorAsociado).toLowerCase()
                const nameB = JSON.stringify(b.productorAsociado).toLowerCase()
                if (nameB < nameA)
                    return -1;
                if (nameB > nameA)
                    return 1;
            })
        }
        if (sortA.sort === 'FECHA ↑') {
            updatedList.sort(function (a, b) {
                return new Date(b.fechaCambioEstado) - new Date(a.fechaCambioEstado);
            })
        }
        if (sortA.sort === 'FECHA ↓ZA') {
            updatedList.sort(function (a, b) {
                return new Date(a.fechaCambioEstado) - new Date(b.fechaCambioEstado);
            })
        }
        if (sortA.sort === 'FECHA ↑') {
            updatedList.sort(function (a, b) {
                return new Date((moment(b.fechaCambioEstado, "DD/MM/YYYY").toDate())) - new Date((moment(a.fechaCambioEstado, "DD/MM/YYYY").toDate()));
            })
        }
        if (sortA.sort === 'FECHA ↓ZA') {
            updatedList.sort(function (a, b) {
                return new Date((moment(a.fechaCambioEstado, "DD/MM/YYYY").toDate())) - new Date((moment(b.fechaCambioEstado, "DD/MM/YYYY").toDate()));
            })
        }
        if (sortA.sort === 'COMPAÑIA ↑') {
            updatedList.sort(function (a, b) {
                const nameA = JSON.stringify(a.compania).toLowerCase()
                const nameB = JSON.stringify(b.compania).toLowerCase()
                if (nameA < nameB && a.compania)
                    return -1;
                if (nameA > nameB && a.compania)
                    return 1;
            })
        }
        if (sortA.sort === 'COMPAÑIA ↓ZA') {
            updatedList.sort(function (a, b) {
                const nameA = JSON.stringify(a.compania).toLowerCase()
                const nameB = JSON.stringify(b.compania).toLowerCase()
                if (nameB < nameA && a.compania)
                    return -1;
                if (nameB > nameA && b.compania)
                    return 1;
            })
        }
        if (sortA.sort === 'CLIENTE ↑') {
            updatedList.sort(function (a, b) {
                const nameA = JSON.stringify(a.nombre).toLowerCase()
                const nameB = JSON.stringify(b.nombre).toLowerCase()
                if (nameA < nameB && a.nombre)
                    return -1;
                if (nameA > nameB && a.nombre)
                    return 1;
            })
        }
        if (sortA.sort === 'CLIENTE ↓ZA') {
            updatedList.sort(function (a, b) {
                const nameA = JSON.stringify(a.nombre).toLowerCase()
                const nameB = JSON.stringify(b.nombre).toLowerCase()
                if (nameB < nameA && a.nombre)
                    return -1;
                if (nameB > nameA && b.nombre)
                    return 1;
            })
        }
        if (sortA.sort === 'VTO ↑') {
            updatedList.sort(function (a, b) {
                return new Date(b.fechaVencimiento) - new Date(a.fechaVencimiento);
            })
        }
        if (sortA.sort === 'VTO ↓ZA') {
            updatedList.sort(function (a, b) {
                return new Date(a.fechaVencimiento) - new Date(b.fechaVencimiento);
            })
        }
        if (sortA.sort === 'VTO ↑') {
            updatedList.sort(function (a, b) {
                return new Date((moment(b.fechaVencimiento, "DD/MM/YYYY").toDate())) - new Date((moment(a.fechaVencimiento, "DD/MM/YYYY").toDate()));
            })
        }
        if (sortA.sort === 'VTO ↓ZA') {
            updatedList.sort(function (a, b) {
                return new Date((moment(a.fechaVencimiento, "DD/MM/YYYY").toDate())) - new Date((moment(b.fechaVencimiento, "DD/MM/YYYY").toDate()));
            })
        }
        if (sortA.sort === 'ULT MOD ↑') {
            updatedList.sort(function (a, b) {
                return new Date(b.ultimaMod) - new Date(a.ultimaMod);
            })
        }
        if (sortA.sort === 'ULT MOD ↓ZA') {
            updatedList.sort(function (a, b) {
                return new Date(a.ultimaMod) - new Date(b.ultimaMod);
            })
        }
        if (sortA.sort === 'ULT MOD ↑') {
            updatedList.sort(function (a, b) {
                return new Date((moment(b.ultimaMod, "DD/MM/YYYY").toDate())) - new Date((moment(a.ultimaMod, "DD/MM/YYYY").toDate()));
            })
        }
        if (sortA.sort === 'ULT MOD ↓ZA') {
            updatedList.sort(function (a, b) {
                return new Date((moment(a.ultimaMod, "DD/MM/YYYY").toDate())) - new Date((moment(b.ultimaMod, "DD/MM/YYYY").toDate()));
            })
        }
        if (sortA.sort === 'STRO ↑') {
            updatedList.sort(function (a, b) {
                return new Date(b.fechaStro) - new Date(a.fechaStro);
            })
        }
        if (sortA.stro === 'STRO ↓ZA') {
            updatedList.sort(function (a, b) {
                return new Date(a.fechaStro) - new Date(b.fechaStro);
            })
        }
        if (sortA.sort === 'ESTADO ↑') {
            updatedList.sort(function (a, b) {
                { if (a.estado && b.estado) return JSON.stringify(a.estado).localeCompare(JSON.stringify(b.estado)) }
            })
        }
        if (sortA.sort === 'ESTADO ↓ZA') {
            updatedList.sort(function (a, b) {
                { if (a.estado && b.estado) return JSON.stringify(b.estado).localeCompare(JSON.stringify(a.estado)) }
            })
        }
        if (sortA.sort === 0) {
            updatedList.sort((a, b) => b)
        }
    }
    sort()
    let itemsPerPage = 20
    const [itemOffset, setItemOffset] = useState(0);
    const endOffset = itemOffset + itemsPerPage;
    const currentItems = updatedList.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(updatedList.length / itemsPerPage);
    const [currentPage, setCurrentPage] = useState(0);
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % updatedList.length;
        setItemOffset(newOffset);
        setCurrentPage(event.selected);
    }
    useEffect(() => {
        setItemOffset(0);
        setCurrentPage(0);
    }, [updatedList.length]);
    const img = { heigth: '44px', width: '44px', display: 'block' }
    const aka = { marginLeft: '16px', marginRight: '16px' }
    const botonSort = { border: 'none', background: 'none' }
    const iconSort = { color: '#FFFFFF' }


    let updatedMovil = expedientes;
    const [inputMovil, setInputMovil] = useState('')
    const filtroMovil = () => {
        if (inputMovil) {
            updatedMovil = updatedMovil.filter((expediente) =>
                JSON.stringify(expediente.nro).includes(inputMovil) ||
                JSON.stringify(expediente.dominioCliente).toLowerCase().includes(JSON.stringify(inputMovil).toLowerCase().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')) ||
                JSON.stringify(expediente.nombre).toLowerCase().includes(JSON.stringify(inputMovil).toLowerCase().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''))
            );
        }
    };
    filtroMovil();

    const isMobile375 = useMediaQuery({ query: '(max-width: 375px)' });
    const isMobile412 = useMediaQuery({ query: '(min-width: 376px) and (max-width: 412px)' });
    const isMobile480 = useMediaQuery({ query: '(min-width: 413px) and (max-width: 480px)' });
    const isTablet768 = useMediaQuery({ query: '(min-width: 480px) and (max-width: 900px)' });
    const isPc = useMediaQuery({ query: '(min-width: 900px) and (max-width: 1920px)' });
    const isPcBig = useMediaQuery({ query: '(min-width: 1920px)' })
    return (
        <>
            { isPc ?
                    <main className="mainTabla pb-5">
                        {cookies.user ? (
                            <section className="row sectionExpedientesGral">
                                <div className="col-10">
                                    {preCarga === true ? (updatedList.length > 0 ?
                                        <div className="ms-2">
                                            <div className="tablaHead row ps-3 m-0">
                                                {sortA.sort === 'NRO ↑' ?
                                                    <div class="col p-0 d-flex">
                                                        <p className="tituloTabla ps-2">NRO</p>
                                                        <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'NRO ↓' }))}><i class="bi bi-sort-up" style={iconSort} ></i></button>
                                                    </div> : <div className="d-none"></div>}
                                                {sortA.sort === 'NRO ↓' ?
                                                    <div class="col p-0 d-flex">
                                                        <p className="tituloTabla ps-2">NRO</p>
                                                        <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'NRO ↑' }))}><i class="bi bi-sort-down" style={iconSort} ></i></button>
                                                    </div> : <div className="d-none"></div>}
                                                {sortA.sort !== 'NRO ↑' && sortA.sort !== 'NRO ↓' ?
                                                    <div class="col p-0 d-flex">
                                                        <p className="tituloTabla ps-2">NRO</p>
                                                        <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'NRO ↑' }))}><i class="bi bi-filter" style={iconSort} ></i></button>
                                                    </div> : <div className="d-none"></div>}


                                                {sortA.sort === 'PATENTE ↑' ? <div class="col p-0 d-flex">
                                                    <p className="tituloTabla">PATENTE</p>
                                                    <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'PATENTE ↓ZA' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                                                </div> : <div className="d-none"></div>}
                                                {sortA.sort === 'PATENTE ↓ZA' ? <div class="col p-0 d-flex">
                                                    <p className="tituloTabla">PATENTE</p>
                                                    <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'PATENTE ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                                                </div> : <div className="d-none"></div>}
                                                {sortA.sort !== 'PATENTE ↑' && sortA.sort !== 'PATENTE ↓ZA' ? <div class="col p-0 d-flex">
                                                    <p className="tituloTabla">PATENTE</p>
                                                    <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'PATENTE ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                                                </div> : <div className="d-none"></div>}

                                                {sortA.sort === 'PAS ↑' ? <div class="col p-0 d-flex">
                                                    <p className="tituloTabla ps-1">PAS</p>
                                                    <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'PAS ↓ZA' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                                                </div> : <div className="d-none"></div>}
                                                {sortA.sort === 'PAS ↓ZA' ? <div class="col p-0 d-flex">
                                                    <p className="tituloTabla ps-1">PAS</p>
                                                    <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'PAS ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                                                </div> : <div className="d-none"></div>}
                                                {sortA.sort !== 'PAS ↑' && sortA.sort !== 'PAS ↓ZA' ? <div class="col p-0 d-flex">
                                                    <p className="tituloTabla ps-1">PAS</p>
                                                    <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'PAS ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                                                </div> : <div className="d-none"></div>}

                                                {sortA.sort === 'FECHA ↑' ? <div class="col p-0 d-flex">
                                                    <p className="tituloTabla ps-1">F.C ESTADO</p>
                                                    <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'FECHA ↓ZA' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                                                </div> : <div className="d-none"></div>}
                                                {sortA.sort === 'FECHA ↓ZA' ? <div class="col p-0 d-flex">
                                                    <p className="tituloTabla ps-1">F.C ESTADO</p>
                                                    <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'FECHA ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                                                </div> : <div className="d-none"></div>}
                                                {sortA.sort !== 'FECHA ↑' && sortA.sort !== 'FECHA ↓ZA' ? <div class="col p-0 d-flex">
                                                    <p className="tituloTabla ps-1">F.C ESTADO</p>
                                                    <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'FECHA ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                                                </div> : <div className="d-none"></div>}

                                                {sortA.sort === 'COMPAÑIA ↑' ? <div class="col p-0 d-flex">
                                                    <p className="tituloTabla ps-1">CIA</p>
                                                    <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'COMPAÑIA ↓ZA' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                                                </div> : <div className="d-none"></div>}
                                                {sortA.sort === 'COMPAÑIA ↓ZA' ? <div class="col p-0 d-flex">
                                                    <p className="tituloTabla ps-1">CIA</p>
                                                    <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'COMPAÑIA ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                                                </div> : <div className="d-none"></div>}
                                                {sortA.sort !== 'COMPAÑIA ↑' && sortA.sort !== 'COMPAÑIA ↓ZA' ? <div class="col p-0 d-flex">
                                                    <p className="tituloTabla ps-1">CIA</p>
                                                    <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'COMPAÑIA ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                                                </div> : <div className="d-none"></div>}


                                                {sortA.sort === 'CLIENTE ↑' ? <div class="col-3 p-0 d-flex">
                                                    <p className="tituloTabla ps-1">CLIENTE</p>
                                                    <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'CLIENTE ↓ZA' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                                                </div> : <div className="d-none"></div>}
                                                {sortA.sort === 'CLIENTE ↓ZA' ? <div class="col-3 p-0 d-flex">
                                                    <p className="tituloTabla ps-1">CLIENTE</p>
                                                    <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'CLIENTE ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                                                </div> : <div className="d-none"></div>}
                                                {sortA.sort !== 'CLIENTE ↑' && sortA.sort !== 'CLIENTE ↓ZA' ? <div class="col-3 p-0 d-flex">
                                                    <p className="tituloTabla ps-1">CLIENTE</p>
                                                    <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'CLIENTE ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                                                </div> : <div className="d-none"></div>}


                                                {sortA.sort === 'VTO ↑' ? <div class="col p-0 d-flex">
                                                    <p className="tituloTabla ps-1">F VTO</p>
                                                    <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'VTO ↓ZA' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                                                </div> : <div className="d-none"></div>}
                                                {sortA.sort === 'VTO ↓ZA' ? <div class="col p-0 d-flex">
                                                    <p className="tituloTabla ps-1">F VTO</p>
                                                    <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'VTO ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                                                </div> : <div className="d-none"></div>}
                                                {sortA.sort !== 'VTO ↑' && sortA.sort !== 'VTO ↓ZA' ? <div class="col p-0 d-flex">
                                                    <p className="tituloTabla ps-1">F VTO</p>
                                                    <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'VTO ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                                                </div> : <div className="d-none"></div>}

                                                {sortA.sort === 'NIVEL ↑' ? <div class="col p-0 d-flex">
                                                    <p className="tituloTabla ps-1">NIVEL</p>
                                                    <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'NIVEL ↓' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                                                </div> : <div className="d-none"></div>}
                                                {sortA.sort === 'NIVEL ↓' ? <div class="col p-0 d-flex">
                                                    <p className="tituloTabla ps-1">NIVEL</p>
                                                    <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'NIVEL ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                                                </div> : <div className="d-none"></div>}
                                                {sortA.sort !== 'NIVEL ↑' && sortA.sort !== 'NIVEL ↓' ? <div class="col p-0 d-flex">
                                                    <p className="tituloTabla ps-1">NIVEL</p>
                                                    <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'NIVEL ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                                                </div> : <div className="d-none"></div>}

                                                {sortA.sort === 'ESTADO ↑' ? <div class="col p-0 d-flex">
                                                    <p className="tituloTabla ps-1">ESTADO</p>
                                                    <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'ESTADO ↓ZA' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                                                </div> : <div className="d-none"></div>}
                                                {sortA.sort === 'ESTADO ↓ZA' ? <div class="col p-0 d-flex">
                                                    <p className="tituloTabla ps-1">ESTADO</p>
                                                    <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'ESTADO ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                                                </div> : <div className="d-none"></div>}
                                                {sortA.sort !== 'ESTADO ↑' && sortA.sort !== 'ESTADO ↓ZA' ? <div class="col p-0 d-flex">
                                                    <p className="tituloTabla ps-1">ESTADO</p>
                                                    <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'ESTADO ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                                                </div> : <div className="d-none"></div>}
                                            </div>
                                            <div class=" mt-3">
                                                <div style={aka}>{updatedList.slice(itemOffset, Number(itemOffset + 20)).map((expediente) => {
                                                    return (
                                                        <Expediente
                                                            expediente={expediente}
                                                            key={expediente.id}
                                                        />
                                                    );
                                                })}</div>
                                            </div>
                                        </div>
                                        :
                                        <div className="">
                                            <div className="table-responsive scrollbar divTabla">
                                                <div className="tablaHead row ps-3 m-0">
                                                    {sortA.sort === 'NRO ↑' ?
                                                        <div class="col p-0 d-flex">
                                                            <p className="tituloTabla ps-2">NRO</p>
                                                            <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'NRO ↓' }))}><i class="bi bi-sort-up" style={iconSort} ></i></button>
                                                        </div> : <div className="d-none"></div>}
                                                    {sortA.sort === 'NRO ↓' ?
                                                        <div class="col p-0 d-flex">
                                                            <p className="tituloTabla ps-2">NRO</p>
                                                            <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'NRO ↑' }))}><i class="bi bi-sort-down" style={iconSort} ></i></button>
                                                        </div> : <div className="d-none"></div>}
                                                    {sortA.sort !== 'NRO ↑' && sortA.sort !== 'NRO ↓' ?
                                                        <div class="col p-0 d-flex">
                                                            <p className="tituloTabla ps-2">NRO</p>
                                                            <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'NRO ↑' }))}><i class="bi bi-filter" style={iconSort} ></i></button>
                                                        </div> : <div className="d-none"></div>}


                                                    {sortA.sort === 'PATENTE ↑' ? <div class="col p-0 d-flex">
                                                        <p className="tituloTabla">PATENTE</p>
                                                        <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'PATENTE ↓ZA' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                                                    </div> : <div className="d-none"></div>}
                                                    {sortA.sort === 'PATENTE ↓ZA' ? <div class="col p-0 d-flex">
                                                        <p className="tituloTabla">PATENTE</p>
                                                        <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'PATENTE ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                                                    </div> : <div className="d-none"></div>}
                                                    {sortA.sort !== 'PATENTE ↑' && sortA.sort !== 'PATENTE ↓ZA' ? <div class="col p-0 d-flex">
                                                        <p className="tituloTabla">PATENTE</p>
                                                        <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'PATENTE ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                                                    </div> : <div className="d-none"></div>}

                                                    {sortA.sort === 'PAS ↑' ? <div class="col p-0 d-flex">
                                                        <p className="tituloTabla ps-1">PAS</p>
                                                        <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'PAS ↓ZA' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                                                    </div> : <div className="d-none"></div>}
                                                    {sortA.sort === 'PAS ↓ZA' ? <div class="col p-0 d-flex">
                                                        <p className="tituloTabla ps-1">PAS</p>
                                                        <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'PAS ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                                                    </div> : <div className="d-none"></div>}
                                                    {sortA.sort !== 'PAS ↑' && sortA.sort !== 'PAS ↓ZA' ? <div class="col p-0 d-flex">
                                                        <p className="tituloTabla ps-1">PAS</p>
                                                        <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'PAS ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                                                    </div> : <div className="d-none"></div>}

                                                    {sortA.sort === 'FECHA ↑' ? <div class="col p-0 d-flex">
                                                        <p className="tituloTabla ps-1">F.C ESTADO</p>
                                                        <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'FECHA ↓ZA' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                                                    </div> : <div className="d-none"></div>}
                                                    {sortA.sort === 'FECHA ↓ZA' ? <div class="col p-0 d-flex">
                                                        <p className="tituloTabla ps-1">F.C ESTADO</p>
                                                        <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'FECHA ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                                                    </div> : <div className="d-none"></div>}
                                                    {sortA.sort !== 'FECHA ↑' && sortA.sort !== 'FECHA ↓ZA' ? <div class="col p-0 d-flex">
                                                        <p className="tituloTabla ps-1">F.C ESTADO</p>
                                                        <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'FECHA ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                                                    </div> : <div className="d-none"></div>}

                                                    {sortA.sort === 'COMPAÑIA ↑' ? <div class="col p-0 d-flex">
                                                        <p className="tituloTabla ps-1">CIA</p>
                                                        <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'COMPAÑIA ↓ZA' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                                                    </div> : <div className="d-none"></div>}
                                                    {sortA.sort === 'COMPAÑIA ↓ZA' ? <div class="col p-0 d-flex">
                                                        <p className="tituloTabla ps-1">CIA</p>
                                                        <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'COMPAÑIA ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                                                    </div> : <div className="d-none"></div>}
                                                    {sortA.sort !== 'COMPAÑIA ↑' && sortA.sort !== 'COMPAÑIA ↓ZA' ? <div class="col p-0 d-flex">
                                                        <p className="tituloTabla ps-1">CIA</p>
                                                        <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'COMPAÑIA ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                                                    </div> : <div className="d-none"></div>}


                                                    {sortA.sort === 'CLIENTE ↑' ? <div class="col-3 p-0 d-flex">
                                                        <p className="tituloTabla ps-1">CLIENTE</p>
                                                        <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'CLIENTE ↓ZA' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                                                    </div> : <div className="d-none"></div>}
                                                    {sortA.sort === 'CLIENTE ↓ZA' ? <div class="col-3 p-0 d-flex">
                                                        <p className="tituloTabla ps-1">CLIENTE</p>
                                                        <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'CLIENTE ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                                                    </div> : <div className="d-none"></div>}
                                                    {sortA.sort !== 'CLIENTE ↑' && sortA.sort !== 'CLIENTE ↓ZA' ? <div class="col-3 p-0 d-flex">
                                                        <p className="tituloTabla ps-1">CLIENTE</p>
                                                        <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'CLIENTE ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                                                    </div> : <div className="d-none"></div>}


                                                    {sortA.sort === 'VTO ↑' ? <div class="col p-0 d-flex">
                                                        <p className="tituloTabla ps-1">F VTO</p>
                                                        <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'VTO ↓ZA' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                                                    </div> : <div className="d-none"></div>}
                                                    {sortA.sort === 'VTO ↓ZA' ? <div class="col p-0 d-flex">
                                                        <p className="tituloTabla ps-1">F VTO</p>
                                                        <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'VTO ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                                                    </div> : <div className="d-none"></div>}
                                                    {sortA.sort !== 'VTO ↑' && sortA.sort !== 'VTO ↓ZA' ? <div class="col p-0 d-flex">
                                                        <p className="tituloTabla ps-1">F VTO</p>
                                                        <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'VTO ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                                                    </div> : <div className="d-none"></div>}

                                                    <div className="col p-0 d-flex"></div>
                                                    {sortA.sort === 'ESTADO ↑' ? <div class="col p-0 d-flex">
                                                        <p className="tituloTabla ps-1">ESTADO</p>
                                                        <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'ESTADO ↓ZA' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                                                    </div> : <div className="d-none"></div>}
                                                    {sortA.sort === 'ESTADO ↓ZA' ? <div class="col p-0 d-flex">
                                                        <p className="tituloTabla ps-1">ESTADO</p>
                                                        <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'ESTADO ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                                                    </div> : <div className="d-none"></div>}
                                                    {sortA.sort !== 'ESTADO ↑' && sortA.sort !== 'ESTADO ↓ZA' ? <div class="col p-0 d-flex">
                                                        <p className="tituloTabla ps-1">ESTADO</p>
                                                        <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'ESTADO ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                                                    </div> : <div className="d-none"></div>}
                                                </div>
                                                <h2 className="center letra mt-5">SIN RESULTADOS</h2>
                                            </div>
                                        </div>
                                    ) : <div className="center mt-5 pt-5">
                                        <div class="spinner-border text-primary center" role="status"></div>
                                    </div>}
                                    <div className="center mt-5">
                                        <Excel data={updatedList} />
                                    </div>
                                </div>
                                <div className="col-2 letra">
                                    <div className="filtroTicketContainer me-1">
                                        <div className="d-flex ps-3 pt-3">
                                            <div className="">
                                                <button onClick={(e) => filtrar()} style={btn}><img src={iconoFiltrar} alt="filtrar" style={img}></img>FILTRAR</button>
                                            </div>
                                            <div>
                                                <button onClick={(e) => vaciar()} style={btn}><img src={iconoGlobal} alt="vaciar" style={img}></img>VACIAR</button>
                                            </div>
                                        </div>
                                        <form className="me-3 ms-3 mt-3" onKeyDown={(e) => { if (e.key === 'Enter') { filtrar() } }}>
                                            <div class="">
                                                <input
                                                    placeholder="BUSQUEDA GENERAL"
                                                    type="text"
                                                    value={info.info}
                                                    class="inputsDelFiltroSearch"
                                                    onChange={(e) =>
                                                        setInfo((prevState) => ({
                                                            ...prevState,
                                                            info: e.target.value,
                                                        }))
                                                    }
                                                />
                                            </div>
                                            <div className="row mt-3">
                                                <div className="col">
                                                    <h6 className="subtituloDelFiltro m-0">Asignado</h6>
                                                    <MultiSelect
                                                        options={asignadoInput.sort((a, b) => a.label.localeCompare(b.label))}
                                                        value={info.asignado}
                                                        onChange={(e) => {
                                                            setInfo((prevState) => ({
                                                                ...prevState,
                                                                asignado: e,
                                                            }));
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                <div className="col">
                                                    <h6 className="subtituloDelFiltro m-0">Nivel</h6>
                                                    <MultiSelect
                                                        className=""
                                                        options={nivelInput}
                                                        value={info.nivel}
                                                        onChange={(e) => {
                                                            setInfo((prevState) => ({
                                                                ...prevState,
                                                                nivel: e,
                                                            }));
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                <div className="col">
                                                    <h6 className="subtituloDelFiltro m-0">Vencimiento</h6>
                                                    <MultiSelect
                                                        className=""
                                                        options={fechaInput}
                                                        value={info.fecha}
                                                        onChange={(e) => {
                                                            setInfo((prevState) => ({
                                                                ...prevState,
                                                                fecha: e,
                                                            }));
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                <div className="col">
                                                    <h6 className="subtituloDelFiltro m-0">Compañias</h6>
                                                    <MultiSelect
                                                        className=""
                                                        options={compInput.sort((a, b) => a.label.localeCompare(b.label))}
                                                        value={info.comp}
                                                        onChange={(e) => {
                                                            setInfo((prevState) => ({
                                                                ...prevState,
                                                                comp: e,
                                                            }));
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                <div className="col">
                                                    <h6 className="subtituloDelFiltro m-0">Productores</h6>
                                                    <MultiSelect
                                                        className=""
                                                        options={proInput.sort((a, b) => a.label.localeCompare(b.label))}
                                                        value={info.pas}
                                                        onChange={(e) => {
                                                            setInfo((prevState) => ({
                                                                ...prevState,
                                                                pas: e,
                                                            }));
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="mt-4 center">
                                                <ReactPaginate
                                                    forcePage={currentPage}
                                                    className='center p-0'
                                                    activeClassName={'item active'}
                                                    breakClassName={'item break-me'}
                                                    breakLabel={'...'}
                                                    marginPagesDisplayed={1}
                                                    onPageChange={handlePageClick}
                                                    pageClassName={'item pagination-page'}
                                                    pageRangeDisplayed={5}
                                                    pageCount={pageCount}
                                                />
                                            </div>
                                            <h6 className={info.vista ? 'center' : 'center'}>Cantidad de expedientes: {updatedList.length}</h6>
                                        </form>
                                    </div>
                                </div>
                            </section>
                        ) : (
                            <h4 className="p-5">Se necesita iniciar sesion</h4>
                        )}
                    </main>
                    : isMobile375 ?
                        <main style={{ paddingLeft: '3%', paddingRight: '3%' }}>
                            <h3 className="textoTablaMovil center" style={{ paddingTop: '13vh' }}>INGRESE EXPEDIENTE<br /> PATENTE O NOMBRE</h3>
                            <div className="center" style={{ marginTop: '3vh' }}>
                                <input type="text" className="center text-center inputBuscarTablaMovil" onChange={(e) => setInputMovil(e.target.value)} value={inputMovil} />
                            </div>
                            <div className="center" style={{ marginTop: '3vh' }}><button className="btnBuscarTablaMovil">BUSCAR</button></div>
                            <div style={{ marginTop: '3vh' }}>
                                <div className="row mb-3 pe-3 ps-3">
                                    <div className="textoHeaderTablaMovil col">NRO</div>
                                    <div className="textoHeaderTablaMovil col">PATENTE</div>
                                    <div className="textoHeaderTablaMovil col">F.STRO</div>
                                    <div className="textoHeaderTablaMovil col">COMPAÑIA</div>
                                    <div className="textoHeaderTablaMovil col">ESTADO</div>
                                </div>
                                {updatedMovil?.slice(0, 100).map(e => (
                                    <ExpedienteTablaMovil key={e.id} expediente={e} />
                                ))}

                            </div>
                        </main>
                        : isMobile412 ?
                            <main style={{ paddingLeft: '3%', paddingRight: '3%' }}>
                                <h3 className="textoTablaMovil center" style={{ paddingTop: '13vh' }}>INGRESE EXPEDIENTE<br /> PATENTE O NOMBRE</h3>
                                <div className="center" style={{ marginTop: '3vh' }}>
                                    <input type="text" className="center text-center inputBuscarTablaMovil" onChange={(e) => setInputMovil(e.target.value)} value={inputMovil} />
                                </div>
                                <div className="center" style={{ marginTop: '3vh' }}><button className="btnBuscarTablaMovil">BUSCAR</button></div>
                                <div style={{ marginTop: '3vh' }}>
                                    <div className="row mb-3 pe-3 ps-3">
                                        <div className="textoHeaderTablaMovil col">NRO</div>
                                        <div className="textoHeaderTablaMovil col">PATENTE</div>
                                        <div className="textoHeaderTablaMovil col">F.STRO</div>
                                        <div className="textoHeaderTablaMovil col">COMPAÑIA</div>
                                        <div className="textoHeaderTablaMovil col">ESTADO</div>
                                    </div>
                                    {updatedMovil?.slice(0, 100).map(e => (
                                        <ExpedienteTablaMovil key={e.id} expediente={e} />
                                    ))}

                                </div>
                            </main>
                            : isMobile480 ?
                                <main style={{ paddingLeft: '3%', paddingRight: '3%' }}>
                                    <h3 className="textoTablaMovil center" style={{ paddingTop: '13vh' }}>INGRESE EXPEDIENTE<br /> PATENTE O NOMBRE</h3>
                                    <div className="center" style={{ marginTop: '3vh' }}>
                                        <input type="text" className="center text-center inputBuscarTablaMovil" onChange={(e) => setInputMovil(e.target.value)} value={inputMovil} />
                                    </div>
                                    <div className="center" style={{ marginTop: '3vh' }}><button className="btnBuscarTablaMovil">BUSCAR</button></div>
                                    <div style={{ marginTop: '3vh' }}>
                                        <div className="row mb-3 pe-3 ps-3">
                                            <div className="textoHeaderTablaMovil col">NRO</div>
                                            <div className="textoHeaderTablaMovil col">PATENTE</div>
                                            <div className="textoHeaderTablaMovil col">F.STRO</div>
                                            <div className="textoHeaderTablaMovil col">COMPAÑIA</div>
                                            <div className="textoHeaderTablaMovil col">ESTADO</div>
                                        </div>
                                        {updatedMovil?.slice(0, 100).map(e => (
                                            <ExpedienteTablaMovil key={e.id} expediente={e} />
                                        ))}

                                    </div>
                                </main>
                                : isTablet768 ?
                                    <main style={{ paddingLeft: '3%', paddingRight: '3%' }}>
                                        <h3 className="textoTablaMovil center" style={{ paddingTop: '13vh' }}>INGRESE EXPEDIENTE<br /> PATENTE O NOMBRE</h3>
                                        <div className="center" style={{ marginTop: '3vh' }}>
                                            <input type="text" className="center text-center inputBuscarTablaMovil" onChange={(e) => setInputMovil(e.target.value)} value={inputMovil} />
                                        </div>
                                        <div className="center" style={{ marginTop: '3vh' }}><button className="btnBuscarTablaMovil">BUSCAR</button></div>
                                        <div style={{ marginTop: '3vh' }}>
                                            <div className="row mb-3 pe-3 ps-3">
                                                <div className="textoHeaderTablaMovil col">NRO</div>
                                                <div className="textoHeaderTablaMovil col">PATENTE</div>
                                                <div className="textoHeaderTablaMovil col">F.STRO</div>
                                                <div className="textoHeaderTablaMovil col">COMPAÑIA</div>
                                                <div className="textoHeaderTablaMovil col">ESTADO</div>
                                            </div>
                                            {updatedMovil?.slice(0, 100).map(e => (
                                                <ExpedienteTablaMovil key={e.id} expediente={e} />
                                            ))}
                                        </div>
                                    </main>
                                    : <main className="mainTabla pb-5">
                                    {cookies.user ? (
                                        <section className="row sectionExpedientesGral">
                                            <div className="col-10">
                                                {preCarga === true ? (updatedList.length > 0 ?
                                                    <div className="ms-2">
                                                        <div className="tablaHead row ps-3 m-0">
                                                            {sortA.sort === 'NRO ↑' ?
                                                                <div class="col p-0 d-flex">
                                                                    <p className="tituloTabla ps-2">NRO</p>
                                                                    <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'NRO ↓' }))}><i class="bi bi-sort-up" style={iconSort} ></i></button>
                                                                </div> : <div className="d-none"></div>}
                                                            {sortA.sort === 'NRO ↓' ?
                                                                <div class="col p-0 d-flex">
                                                                    <p className="tituloTabla ps-2">NRO</p>
                                                                    <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'NRO ↑' }))}><i class="bi bi-sort-down" style={iconSort} ></i></button>
                                                                </div> : <div className="d-none"></div>}
                                                            {sortA.sort !== 'NRO ↑' && sortA.sort !== 'NRO ↓' ?
                                                                <div class="col p-0 d-flex">
                                                                    <p className="tituloTabla ps-2">NRO</p>
                                                                    <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'NRO ↑' }))}><i class="bi bi-filter" style={iconSort} ></i></button>
                                                                </div> : <div className="d-none"></div>}
                
                
                                                            {sortA.sort === 'PATENTE ↑' ? <div class="col p-0 d-flex">
                                                                <p className="tituloTabla">PATENTE</p>
                                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'PATENTE ↓ZA' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                                                            </div> : <div className="d-none"></div>}
                                                            {sortA.sort === 'PATENTE ↓ZA' ? <div class="col p-0 d-flex">
                                                                <p className="tituloTabla">PATENTE</p>
                                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'PATENTE ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                                                            </div> : <div className="d-none"></div>}
                                                            {sortA.sort !== 'PATENTE ↑' && sortA.sort !== 'PATENTE ↓ZA' ? <div class="col p-0 d-flex">
                                                                <p className="tituloTabla">PATENTE</p>
                                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'PATENTE ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                                                            </div> : <div className="d-none"></div>}
                
                                                            {sortA.sort === 'PAS ↑' ? <div class="col p-0 d-flex">
                                                                <p className="tituloTabla ps-1">PAS</p>
                                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'PAS ↓ZA' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                                                            </div> : <div className="d-none"></div>}
                                                            {sortA.sort === 'PAS ↓ZA' ? <div class="col p-0 d-flex">
                                                                <p className="tituloTabla ps-1">PAS</p>
                                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'PAS ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                                                            </div> : <div className="d-none"></div>}
                                                            {sortA.sort !== 'PAS ↑' && sortA.sort !== 'PAS ↓ZA' ? <div class="col p-0 d-flex">
                                                                <p className="tituloTabla ps-1">PAS</p>
                                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'PAS ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                                                            </div> : <div className="d-none"></div>}
                
                                                            {sortA.sort === 'FECHA ↑' ? <div class="col p-0 d-flex">
                                                                <p className="tituloTabla ps-1">F.C ESTADO</p>
                                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'FECHA ↓ZA' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                                                            </div> : <div className="d-none"></div>}
                                                            {sortA.sort === 'FECHA ↓ZA' ? <div class="col p-0 d-flex">
                                                                <p className="tituloTabla ps-1">F.C ESTADO</p>
                                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'FECHA ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                                                            </div> : <div className="d-none"></div>}
                                                            {sortA.sort !== 'FECHA ↑' && sortA.sort !== 'FECHA ↓ZA' ? <div class="col p-0 d-flex">
                                                                <p className="tituloTabla ps-1">F.C ESTADO</p>
                                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'FECHA ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                                                            </div> : <div className="d-none"></div>}
                
                                                            {sortA.sort === 'COMPAÑIA ↑' ? <div class="col p-0 d-flex">
                                                                <p className="tituloTabla ps-1">CIA</p>
                                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'COMPAÑIA ↓ZA' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                                                            </div> : <div className="d-none"></div>}
                                                            {sortA.sort === 'COMPAÑIA ↓ZA' ? <div class="col p-0 d-flex">
                                                                <p className="tituloTabla ps-1">CIA</p>
                                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'COMPAÑIA ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                                                            </div> : <div className="d-none"></div>}
                                                            {sortA.sort !== 'COMPAÑIA ↑' && sortA.sort !== 'COMPAÑIA ↓ZA' ? <div class="col p-0 d-flex">
                                                                <p className="tituloTabla ps-1">CIA</p>
                                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'COMPAÑIA ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                                                            </div> : <div className="d-none"></div>}
                
                
                                                            {sortA.sort === 'CLIENTE ↑' ? <div class="col-3 p-0 d-flex">
                                                                <p className="tituloTabla ps-1">CLIENTE</p>
                                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'CLIENTE ↓ZA' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                                                            </div> : <div className="d-none"></div>}
                                                            {sortA.sort === 'CLIENTE ↓ZA' ? <div class="col-3 p-0 d-flex">
                                                                <p className="tituloTabla ps-1">CLIENTE</p>
                                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'CLIENTE ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                                                            </div> : <div className="d-none"></div>}
                                                            {sortA.sort !== 'CLIENTE ↑' && sortA.sort !== 'CLIENTE ↓ZA' ? <div class="col-3 p-0 d-flex">
                                                                <p className="tituloTabla ps-1">CLIENTE</p>
                                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'CLIENTE ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                                                            </div> : <div className="d-none"></div>}
                
                
                                                            {sortA.sort === 'VTO ↑' ? <div class="col p-0 d-flex">
                                                                <p className="tituloTabla ps-1">F VTO</p>
                                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'VTO ↓ZA' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                                                            </div> : <div className="d-none"></div>}
                                                            {sortA.sort === 'VTO ↓ZA' ? <div class="col p-0 d-flex">
                                                                <p className="tituloTabla ps-1">F VTO</p>
                                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'VTO ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                                                            </div> : <div className="d-none"></div>}
                                                            {sortA.sort !== 'VTO ↑' && sortA.sort !== 'VTO ↓ZA' ? <div class="col p-0 d-flex">
                                                                <p className="tituloTabla ps-1">F VTO</p>
                                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'VTO ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                                                            </div> : <div className="d-none"></div>}
                
                                                            {sortA.sort === 'NIVEL ↑' ? <div class="col p-0 d-flex">
                                                                <p className="tituloTabla ps-1">NIVEL</p>
                                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'NIVEL ↓' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                                                            </div> : <div className="d-none"></div>}
                                                            {sortA.sort === 'NIVEL ↓' ? <div class="col p-0 d-flex">
                                                                <p className="tituloTabla ps-1">NIVEL</p>
                                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'NIVEL ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                                                            </div> : <div className="d-none"></div>}
                                                            {sortA.sort !== 'NIVEL ↑' && sortA.sort !== 'NIVEL ↓' ? <div class="col p-0 d-flex">
                                                                <p className="tituloTabla ps-1">NIVEL</p>
                                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'NIVEL ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                                                            </div> : <div className="d-none"></div>}
                
                                                            {sortA.sort === 'ESTADO ↑' ? <div class="col p-0 d-flex">
                                                                <p className="tituloTabla ps-1">ESTADO</p>
                                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'ESTADO ↓ZA' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                                                            </div> : <div className="d-none"></div>}
                                                            {sortA.sort === 'ESTADO ↓ZA' ? <div class="col p-0 d-flex">
                                                                <p className="tituloTabla ps-1">ESTADO</p>
                                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'ESTADO ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                                                            </div> : <div className="d-none"></div>}
                                                            {sortA.sort !== 'ESTADO ↑' && sortA.sort !== 'ESTADO ↓ZA' ? <div class="col p-0 d-flex">
                                                                <p className="tituloTabla ps-1">ESTADO</p>
                                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'ESTADO ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                                                            </div> : <div className="d-none"></div>}
                                                        </div>
                                                        <div class=" mt-3">
                                                            <div style={aka}>{updatedList.slice(itemOffset, Number(itemOffset + 20)).map((expediente) => {
                                                                return (
                                                                    <Expediente
                                                                        expediente={expediente}
                                                                        key={expediente.id}
                                                                    />
                                                                );
                                                            })}</div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="">
                                                        <div className="table-responsive scrollbar divTabla">
                                                            <div className="tablaHead row ps-3 m-0">
                                                                {sortA.sort === 'NRO ↑' ?
                                                                    <div class="col p-0 d-flex">
                                                                        <p className="tituloTabla ps-2">NRO</p>
                                                                        <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'NRO ↓' }))}><i class="bi bi-sort-up" style={iconSort} ></i></button>
                                                                    </div> : <div className="d-none"></div>}
                                                                {sortA.sort === 'NRO ↓' ?
                                                                    <div class="col p-0 d-flex">
                                                                        <p className="tituloTabla ps-2">NRO</p>
                                                                        <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'NRO ↑' }))}><i class="bi bi-sort-down" style={iconSort} ></i></button>
                                                                    </div> : <div className="d-none"></div>}
                                                                {sortA.sort !== 'NRO ↑' && sortA.sort !== 'NRO ↓' ?
                                                                    <div class="col p-0 d-flex">
                                                                        <p className="tituloTabla ps-2">NRO</p>
                                                                        <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'NRO ↑' }))}><i class="bi bi-filter" style={iconSort} ></i></button>
                                                                    </div> : <div className="d-none"></div>}
                
                
                                                                {sortA.sort === 'PATENTE ↑' ? <div class="col p-0 d-flex">
                                                                    <p className="tituloTabla">PATENTE</p>
                                                                    <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'PATENTE ↓ZA' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                                                                </div> : <div className="d-none"></div>}
                                                                {sortA.sort === 'PATENTE ↓ZA' ? <div class="col p-0 d-flex">
                                                                    <p className="tituloTabla">PATENTE</p>
                                                                    <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'PATENTE ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                                                                </div> : <div className="d-none"></div>}
                                                                {sortA.sort !== 'PATENTE ↑' && sortA.sort !== 'PATENTE ↓ZA' ? <div class="col p-0 d-flex">
                                                                    <p className="tituloTabla">PATENTE</p>
                                                                    <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'PATENTE ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                                                                </div> : <div className="d-none"></div>}
                
                                                                {sortA.sort === 'PAS ↑' ? <div class="col p-0 d-flex">
                                                                    <p className="tituloTabla ps-1">PAS</p>
                                                                    <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'PAS ↓ZA' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                                                                </div> : <div className="d-none"></div>}
                                                                {sortA.sort === 'PAS ↓ZA' ? <div class="col p-0 d-flex">
                                                                    <p className="tituloTabla ps-1">PAS</p>
                                                                    <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'PAS ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                                                                </div> : <div className="d-none"></div>}
                                                                {sortA.sort !== 'PAS ↑' && sortA.sort !== 'PAS ↓ZA' ? <div class="col p-0 d-flex">
                                                                    <p className="tituloTabla ps-1">PAS</p>
                                                                    <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'PAS ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                                                                </div> : <div className="d-none"></div>}
                
                                                                {sortA.sort === 'FECHA ↑' ? <div class="col p-0 d-flex">
                                                                    <p className="tituloTabla ps-1">F.C ESTADO</p>
                                                                    <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'FECHA ↓ZA' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                                                                </div> : <div className="d-none"></div>}
                                                                {sortA.sort === 'FECHA ↓ZA' ? <div class="col p-0 d-flex">
                                                                    <p className="tituloTabla ps-1">F.C ESTADO</p>
                                                                    <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'FECHA ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                                                                </div> : <div className="d-none"></div>}
                                                                {sortA.sort !== 'FECHA ↑' && sortA.sort !== 'FECHA ↓ZA' ? <div class="col p-0 d-flex">
                                                                    <p className="tituloTabla ps-1">F.C ESTADO</p>
                                                                    <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'FECHA ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                                                                </div> : <div className="d-none"></div>}
                
                                                                {sortA.sort === 'COMPAÑIA ↑' ? <div class="col p-0 d-flex">
                                                                    <p className="tituloTabla ps-1">CIA</p>
                                                                    <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'COMPAÑIA ↓ZA' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                                                                </div> : <div className="d-none"></div>}
                                                                {sortA.sort === 'COMPAÑIA ↓ZA' ? <div class="col p-0 d-flex">
                                                                    <p className="tituloTabla ps-1">CIA</p>
                                                                    <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'COMPAÑIA ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                                                                </div> : <div className="d-none"></div>}
                                                                {sortA.sort !== 'COMPAÑIA ↑' && sortA.sort !== 'COMPAÑIA ↓ZA' ? <div class="col p-0 d-flex">
                                                                    <p className="tituloTabla ps-1">CIA</p>
                                                                    <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'COMPAÑIA ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                                                                </div> : <div className="d-none"></div>}
                
                
                                                                {sortA.sort === 'CLIENTE ↑' ? <div class="col-3 p-0 d-flex">
                                                                    <p className="tituloTabla ps-1">CLIENTE</p>
                                                                    <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'CLIENTE ↓ZA' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                                                                </div> : <div className="d-none"></div>}
                                                                {sortA.sort === 'CLIENTE ↓ZA' ? <div class="col-3 p-0 d-flex">
                                                                    <p className="tituloTabla ps-1">CLIENTE</p>
                                                                    <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'CLIENTE ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                                                                </div> : <div className="d-none"></div>}
                                                                {sortA.sort !== 'CLIENTE ↑' && sortA.sort !== 'CLIENTE ↓ZA' ? <div class="col-3 p-0 d-flex">
                                                                    <p className="tituloTabla ps-1">CLIENTE</p>
                                                                    <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'CLIENTE ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                                                                </div> : <div className="d-none"></div>}
                
                
                                                                {sortA.sort === 'VTO ↑' ? <div class="col p-0 d-flex">
                                                                    <p className="tituloTabla ps-1">F VTO</p>
                                                                    <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'VTO ↓ZA' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                                                                </div> : <div className="d-none"></div>}
                                                                {sortA.sort === 'VTO ↓ZA' ? <div class="col p-0 d-flex">
                                                                    <p className="tituloTabla ps-1">F VTO</p>
                                                                    <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'VTO ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                                                                </div> : <div className="d-none"></div>}
                                                                {sortA.sort !== 'VTO ↑' && sortA.sort !== 'VTO ↓ZA' ? <div class="col p-0 d-flex">
                                                                    <p className="tituloTabla ps-1">F VTO</p>
                                                                    <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'VTO ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                                                                </div> : <div className="d-none"></div>}
                
                                                                <div className="col p-0 d-flex"></div>
                                                                {sortA.sort === 'ESTADO ↑' ? <div class="col p-0 d-flex">
                                                                    <p className="tituloTabla ps-1">ESTADO</p>
                                                                    <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'ESTADO ↓ZA' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                                                                </div> : <div className="d-none"></div>}
                                                                {sortA.sort === 'ESTADO ↓ZA' ? <div class="col p-0 d-flex">
                                                                    <p className="tituloTabla ps-1">ESTADO</p>
                                                                    <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'ESTADO ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                                                                </div> : <div className="d-none"></div>}
                                                                {sortA.sort !== 'ESTADO ↑' && sortA.sort !== 'ESTADO ↓ZA' ? <div class="col p-0 d-flex">
                                                                    <p className="tituloTabla ps-1">ESTADO</p>
                                                                    <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'ESTADO ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                                                                </div> : <div className="d-none"></div>}
                                                            </div>
                                                            <h2 className="center letra mt-5">SIN RESULTADOS</h2>
                                                        </div>
                                                    </div>
                                                ) : <div className="center mt-5 pt-5">
                                                    <div class="spinner-border text-primary center" role="status"></div>
                                                </div>}
                                                <div className="center mt-5">
                                                    <Excel data={updatedList} />
                                                </div>
                                            </div>
                                            <div className="col-2 letra">
                                                <div className="filtroTicketContainer me-1">
                                                    <div className="d-flex ps-3 pt-3">
                                                        <div className="">
                                                            <button onClick={(e) => filtrar()} style={btn}><img src={iconoFiltrar} alt="filtrar" style={img}></img>FILTRAR</button>
                                                        </div>
                                                        <div>
                                                            <button onClick={(e) => vaciar()} style={btn}><img src={iconoGlobal} alt="vaciar" style={img}></img>VACIAR</button>
                                                        </div>
                                                    </div>
                                                    <form className="me-3 ms-3 mt-3" onKeyDown={(e) => { if (e.key === 'Enter') { filtrar() } }}>
                                                        <div class="">
                                                            <input
                                                                placeholder="BUSQUEDA GENERAL"
                                                                type="text"
                                                                value={info.info}
                                                                class="inputsDelFiltroSearch"
                                                                onChange={(e) =>
                                                                    setInfo((prevState) => ({
                                                                        ...prevState,
                                                                        info: e.target.value,
                                                                    }))
                                                                }
                                                            />
                                                        </div>
                                                        <div className="row mt-3">
                                                            <div className="col">
                                                                <h6 className="subtituloDelFiltro m-0">Asignado</h6>
                                                                <MultiSelect
                                                                    options={asignadoInput.sort((a, b) => a.label.localeCompare(b.label))}
                                                                    value={info.asignado}
                                                                    onChange={(e) => {
                                                                        setInfo((prevState) => ({
                                                                            ...prevState,
                                                                            asignado: e,
                                                                        }));
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row mt-3">
                                                            <div className="col">
                                                                <h6 className="subtituloDelFiltro m-0">Nivel</h6>
                                                                <MultiSelect
                                                                    className=""
                                                                    options={nivelInput}
                                                                    value={info.nivel}
                                                                    onChange={(e) => {
                                                                        setInfo((prevState) => ({
                                                                            ...prevState,
                                                                            nivel: e,
                                                                        }));
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row mt-3">
                                                            <div className="col">
                                                                <h6 className="subtituloDelFiltro m-0">Vencimiento</h6>
                                                                <MultiSelect
                                                                    className=""
                                                                    options={fechaInput}
                                                                    value={info.fecha}
                                                                    onChange={(e) => {
                                                                        setInfo((prevState) => ({
                                                                            ...prevState,
                                                                            fecha: e,
                                                                        }));
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row mt-3">
                                                            <div className="col">
                                                                <h6 className="subtituloDelFiltro m-0">Compañias</h6>
                                                                <MultiSelect
                                                                    className=""
                                                                    options={compInput.sort((a, b) => a.label.localeCompare(b.label))}
                                                                    value={info.comp}
                                                                    onChange={(e) => {
                                                                        setInfo((prevState) => ({
                                                                            ...prevState,
                                                                            comp: e,
                                                                        }));
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row mt-3">
                                                            <div className="col">
                                                                <h6 className="subtituloDelFiltro m-0">Productores</h6>
                                                                <MultiSelect
                                                                    className=""
                                                                    options={proInput.sort((a, b) => a.label.localeCompare(b.label))}
                                                                    value={info.pas}
                                                                    onChange={(e) => {
                                                                        setInfo((prevState) => ({
                                                                            ...prevState,
                                                                            pas: e,
                                                                        }));
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="mt-4 center">
                                                            <ReactPaginate
                                                                forcePage={currentPage}
                                                                className='center p-0'
                                                                activeClassName={'item active'}
                                                                breakClassName={'item break-me'}
                                                                breakLabel={'...'}
                                                                marginPagesDisplayed={1}
                                                                onPageChange={handlePageClick}
                                                                pageClassName={'item pagination-page'}
                                                                pageRangeDisplayed={5}
                                                                pageCount={pageCount}
                                                            />
                                                        </div>
                                                        <h6 className={info.vista ? 'center' : 'center'}>Cantidad de expedientes: {updatedList.length}</h6>
                                                    </form>
                                                </div>
                                            </div>
                                        </section>
                                    ) : (
                                        <h4 className="p-5">Se necesita iniciar sesion</h4>
                                    )}
                                </main>}
        </>
    );
}
