import { useState } from "react";
import * as XLSX from "xlsx";

export default function Excel({ data }) {
    const [loading, setLoading] = useState(false);

    const handleDownload = () => {
        setLoading(true);
        
        // Define el orden de los campos que deseas
        const fieldsOrder = [
            "id", 
            "nro", 
            "fechaStro", 
            "fechaExp",
            "fechaVencimiento", 
            "fechaDeAsignado", 
            "fechaCambioEstado",
            "ultimaMod", 
            "nombre", 
            "dominioCliente", 
            "productorAsociado", 
            "compania", 
            "estado"
        ];

        // Reordena los campos en los objetos del arreglo
        const sortedData = data.map(item => {
            const sortedItem = {};
            
            // Añade los campos en el orden especificado
            fieldsOrder.forEach(field => {
                if (item[field] !== undefined) {
                    sortedItem[field] = item[field];
                }
            });

            // Añade los campos restantes que no están en fieldsOrder
            Object.keys(item).forEach(field => {
                if (!fieldsOrder.includes(field)) {
                    sortedItem[field] = item[field];
                }
            });

            return sortedItem;
        });

        const libro = XLSX.utils.book_new();
        const hoja = XLSX.utils.json_to_sheet(sortedData);
        XLSX.utils.book_append_sheet(libro, hoja, "Expedientes");

        setTimeout(() => {
            XLSX.writeFile(libro, "data.xlsx");
            setLoading(false);
        }, 1000);
    };

    return (
        <>
            <button className="btn btn-secondary" onClick={handleDownload} disabled={loading}>
                {loading ? "Cargando..." : "Exportar a Excel"}
            </button>
        </>
    );
}


