import './tablaMovil.css'
import { Link } from "react-router-dom";
export default function ExpedienteTablaMovil({ expediente }) {
    const normalizeFecha = (fechaStr) => {
        if (fechaStr.includes("-")) {
            const [anio, mes, dia] = fechaStr.split("-");
            return `${dia}/${mes}/${anio}`;
        }
        if (fechaStr.includes("/")) {
            const [dia, mes, anio] = fechaStr.split("/");
            if (dia.length === 2 && mes.length === 2 && anio.length === 4) {
                return fechaStr;
            }
        }
        return "Formato de fecha no válido";
    };
    return (
        <div className="contenedorExpTablaMovil row mb-2 m-0">
            <div className="textTablaMovil col"><Link to={`/expediente/${expediente.nro}`} style={{ textDecoration: 'none' }}>{expediente.nro}</Link></div>
            <div className="textTablaMovil col">{expediente.dominioCliente}</div>
            <div className="textTablaMovil col">{normalizeFecha(expediente.fechaStro)}</div>
            <div className="textTablaMovil col">{expediente.compania}</div>
            <div className="textTablaMovil col">{expediente.estado}</div>
        </div>
    );
}