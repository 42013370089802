import axios from "axios";
import { useState, useEffect } from "react";
import ExpedientePas from "../components/Expedientes/ExpedientePas"
import "./index.css";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";
import { MultiSelect } from "react-multi-select-component";
import ReactPaginate from 'react-paginate';
import Excel from "./Excel";
import './paginate.css'
import iconoFiltrar from "../images/iconoFiltrar.png";
import iconoGlobal from "../images/iconoGlobal.png";
import Select from 'react-select';
import { useMediaQuery } from 'react-responsive'
import ExpedienteTablaMovil from "../components/TablaMovil/ExpedienteTablaMovil";
export default function TablaOrg() {
    const navigate = useNavigate();
    const a = localStorage.getItem('user');
    if (a === null) {
        navigate('/login')
    }
    const [expedientes, setExpedientes] = useState([]);
    const [productores, setProductores] = useState([]);
    const [companias, setCompanias] = useState([]);
    const [cookies, setCookie, removeCookie] = useCookies(["user"]);
    const [preCarga, setPreCarga] = useState(false)
    const [arrayPas, setArrayPas] = useState([])


    const idsAsoc = cookies.user?.asoc?.split(',') || [];
    useEffect(() => {
        // Obtener productores para filtros
        axios.get("https://api.tuveunchoque.com.ar/api/nuevosUsuarios/pasParaFiltros2")
            .then((res) => {
                setProductores(res.data);
                // Buscar nombres de los productores asociados
                const productoresAsociados = idsAsoc.map((id) => {
                    const productor = res.data.find((prod) => prod.id === Number(id));
                    return productor ? productor.nombre : null;
                }).filter((nombre) => nombre !== null); // Filtrar los nulos si alguna ID no coincide
                setArrayPas(productoresAsociados);
                // Realizar solicitudes para cada productor asociado
                productoresAsociados.forEach((nombre) => {
                    axios.get(`https://api.tuveunchoque.com.ar/api/gerencia/expedientes/tablas/${nombre}`)
                        .then((res) => {
                            setExpedientes((prevState) => {
                                return [...prevState, ...res.data.reverse()];
                            });
                            setPreCarga(true);
                        });
                });
            });

        // Obtener compañías
        axios.get("https://api.tuveunchoque.com.ar/api/gerencia/companias")
            .then((res) => {
                setCompanias(res.data);
            });
    }, []);

    const estadoInput = [
        { label: "RECEPCIONADO", value: "RECEPCIONADO" },
        { label: "SIN DENUNCIA", value: "SIN DENUNCIA" },
        { label: "ARMADO PRELIMINAR", value: "ARMADO PRELIMINAR" },
        { label: "RECLAMO PRESENTADO", value: "RECLAMO PRESENTADO" },
        { label: "ACEPTACION", value: "ACEPTACION" },
        { label: "EN PROCESO DE PAGO", value: "EN PROCESO DE PAGO" },
        { label: "COBRADO", value: "COBRADO" },
        { label: "MEDIACION", value: "MEDIACION" },
        { label: "CERRADO", value: "CERRADO" },
    ];
    const nivelInput = [
        { label: "0", value: "0" },
        { label: "1", value: "1" },
        { label: "2", value: "2" },
        { label: "3", value: "3" },
    ];
    const fechaInput = [
        { label: "HOY", value: "HOY" },
        { label: "MAÑANA", value: "MAÑANA" },
        { label: "VENCIDOS", value: "VENCIDOS" },
    ];
    const asignadoInput = [
        { label: "AGOSTINA", value: "AGOSTINA" },
        { label: "CATALINA", value: "CATALINA" },
        { label: "DANIELA", value: "DANIELA" },
        { label: "FRANCO", value: "FRANCO" },
        { label: "JUAN", value: "JUAN" },
        { label: "SOFIA", value: "SOFIA" },
        { label: "MICAELA", value: null },
        { label: 'SANDRA', value: 'SANDRA' },
        { label: "", value: null }
    ];
    const compInput = companias.map((c) => {
        return { value: c.compania, label: c.compania };
    });
    const proInput = arrayPas?.map((p) => {
        return { value: p, label: p };
    });
    let updatedList = expedientes;
    const cook = () => {
        if (!cookies.user) {
            navigate("/login");
        }
    };
    cook();
    const [infoTabla, setInfoTabla, removeInfoTabla] = useCookies(["tabla"]);
    const [info, setInfo] = useState({
        info: infoTabla.tabla.info,
        pas: infoTabla.tabla.pas,
        comp: infoTabla.tabla.comp,
        estado: infoTabla.tabla.estado,
        nivel: infoTabla.tabla.nivel,
        fecha: infoTabla.tabla.fecha,
        asignado: infoTabla.tabla.asignado,
        estadoPrueba: infoTabla.tabla.estadoPrueba,
        patente: infoTabla.tabla.patente,
        cliente: infoTabla.tabla.cliente,
        honorariosCobrados: ''
    });
    const conFiltroPas = () => {
        if (infoTabla.tabla.info) {
            updatedList = updatedList.filter((expediente) =>
                JSON.stringify(expediente.nro).includes(infoTabla.tabla.info) ||
                JSON.stringify(expediente.dominioCliente).toLowerCase().includes(JSON.stringify(infoTabla.tabla.info).toLowerCase().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')) ||
                JSON.stringify(expediente.productorAsociado).toLowerCase().includes(JSON.stringify(infoTabla.tabla.info).toLowerCase().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')) ||
                JSON.stringify(expediente.nombre).toLowerCase().includes(JSON.stringify(infoTabla.tabla.info).toLowerCase().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')) ||
                JSON.stringify(expediente.compania).toLowerCase().includes(JSON.stringify(infoTabla.tabla.info).toLowerCase().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''))
            );
        }
        if (infoTabla.tabla.comp.length > 0) {
            updatedList = updatedList.filter((expediente) => {
                return infoTabla.tabla.comp.some((ele) => {
                    return ele.value === expediente.compania;
                });
            });
        }
        if (infoTabla.tabla.estado.length > 0) {
            updatedList = updatedList.filter((expediente) => {
                return infoTabla.tabla.estado.some((ele) => {
                    return ele.value === expediente.estado;
                });
            });
        }
        if (infoTabla.tabla.asignado.length > 0) {
            updatedList = updatedList.filter((expediente) => {
                return infoTabla.tabla.asignado.some((ele) => {
                    return ele.value === expediente.asignadoA;
                });
            });
        }
        if (infoTabla.tabla.patente) {
            updatedList = updatedList.filter(
                (expediente) =>
                    JSON.stringify(expediente.dominioCliente)
                        .toLowerCase()
                        .includes(infoTabla.tabla.patente) ||
                    JSON.stringify(expediente.dominioCliente)
                        .toUpperCase()
                        .includes(infoTabla.tabla.patente)
            );
        }
        if (infoTabla.tabla.cliente) {
            updatedList = updatedList.filter(
                (expediente) =>
                    JSON.stringify(expediente.nombre)
                        .toLowerCase()
                        .includes(infoTabla.tabla.cliente) ||
                    JSON.stringify(expediente.nombre)
                        .toUpperCase()
                        .includes(infoTabla.tabla.cliente)
            );
        }
        if (infoTabla.tabla.pas.length > 0) {
            updatedList = updatedList.filter((expediente) => {
                return infoTabla.tabla.pas.some((ele) => {
                    return ele.value === expediente.productorAsociado;
                });
            });
        }
        if (arrayPas.length > 0) {
            updatedList = updatedList.filter((expediente) => {
                return arrayPas.some((ele) => {
                    return ele === expediente.productorAsociado;
                });
            });
        }
        if (info.honorariosCobrados !== '') { updatedList = updatedList.filter((expediente) => expediente.pasCobroHonorarios.trim() === info.honorariosCobrados) }
    }
    conFiltroPas();
    const [abc, setAbc] = useState(20);
    const mas = { fontSize: "40px", color: "#0722A8" };
    const [bcd, setBcd] = useState(20);
    const borde = { background: "none", border: "none" };
    useEffect(() => {
        setCookie('tabla', JSON.stringify(info), { path: '/' });
    }, [info]);
    const filtrar = () => {
        setAbc(20);
        setBcd(20);
        setCookie("tabla", JSON.stringify(info), {
            path: "/",
        });
    };
    const vaciar = () => {
        setAbc(20);
        setBcd(20);
        setInfo({
            info: "",
            pas: [],
            comp: [],
            estado: [],
            nivel: [],
            fecha: [],
            asignado: [],
            patente: "",
            asignado: "",
            honorariosCobrados: ''
        });
    };
    const ordenarInput = [{ label: '', value: 0 }, { label: 'NRO ↑', value: 'NRO ↑' }, { label: 'NRO ↓', value: 'NRO ↓' }, { label: 'PATENTE ↑', value: 'PATENTE ↑' }, { label: 'PATENTE ↓ZA', value: 'PATENTE ↓ZA' }, { label: 'COMPAÑIA ↑', value: 'COMPAÑIA ↑' }, { label: 'COMPAÑIA ↓ZA', value: 'COMPAÑIA ↓ZA' }, { label: 'CLIENTE ↑', value: 'CLIENTE ↑' }, { label: 'CLIENTE ↓ZA', value: 'CLIENTE ↓ZA' }]
    const [sortA, setSortA] = useState(1)
    const sortFunctions = {
        1: (a, b) => b.nro - a.nro,
        2: (a, b) => a.nro - b.nro,
        3: (a, b) => (a.dominioCliente || '').localeCompare(b.dominioCliente || ''),
        4: (a, b) => (b.dominioCliente || '').localeCompare(a.dominioCliente || ''),
        5: (a, b) => new Date(b.fechaStro) - new Date(a.fechaStro),
        6: (a, b) => new Date(a.fechaStro) - new Date(b.fechaStro),
        7: (a, b) => (a.compania || '').localeCompare(b.compania || ''),
        8: (a, b) => (b.compania || '').localeCompare(a.compania || ''),
        9: (a, b) => (a.nombre || '').localeCompare(b.nombre || ''),
        10: (a, b) => (b.nombre || '').localeCompare(a.nombre || ''),
        11: (a, b) => new Date(b.mesEstimado) - new Date(a.mesEstimado),
        12: (a, b) => new Date(a.mesEstimado) - new Date(b.mesEstimado),
        13: (a, b) => { if (a.estado && b.estado) return JSON.stringify(b.estado).localeCompare(JSON.stringify(a.estado)) },
        14: (a, b) => { if (a.estado && b.estado) return JSON.stringify(a.estado).localeCompare(JSON.stringify(b.estado)) },
    };
    const sort = () => {
        const sortFn = sortFunctions[sortA];
        if (sortFn) {
            updatedList.sort(sortFn);
        }
    };
    sort()
    let itemsPerPage = 20
    const [itemOffset, setItemOffset] = useState(0);
    const endOffset = itemOffset + itemsPerPage;
    const currentItems = updatedList.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(updatedList.length / itemsPerPage);
    const [currentPage, setCurrentPage] = useState(0);
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % updatedList.length;
        setItemOffset(newOffset);
        setCurrentPage(event.selected);
    }
    useEffect(() => {
        setItemOffset(0);
        setCurrentPage(0);
    }, [updatedList.length]);
    const aka = { marginLeft: '16px', marginRight: '16px' }
    const img = { heigth: '44px', width: '44px', display: 'block' }
    const btn = { border: 'none', background: 'none', fontSize: '12px', color: '#0089FF', fontWeight: '100' }
    const botonSort = { border: 'none', background: 'none' }
    const iconSort = { color: '#FFFFFF' }


    let updatedMovil = expedientes;
    const [inputMovil, setInputMovil] = useState('')
    const filtroMovil = () => {
        if (inputMovil) {
            updatedMovil = updatedMovil.filter((expediente) =>
                JSON.stringify(expediente.nro).includes(inputMovil) ||
                JSON.stringify(expediente.dominioCliente).toLowerCase().includes(JSON.stringify(inputMovil).toLowerCase().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')) ||
                JSON.stringify(expediente.nombre).toLowerCase().includes(JSON.stringify(inputMovil).toLowerCase().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''))
            );
        }
    };
    filtroMovil();

    const isMobile375 = useMediaQuery({ query: '(max-width: 375px)' });
    const isMobile412 = useMediaQuery({ query: '(min-width: 376px) and (max-width: 412px)' });
    const isMobile480 = useMediaQuery({ query: '(min-width: 413px) and (max-width: 480px)' });
    const isTablet768 = useMediaQuery({ query: '(min-width: 480px) and (max-width: 900px)' });
    const isPc = useMediaQuery({ query: '(min-width: 900px) and (max-width: 1920px)' });
    const isPcBig = useMediaQuery({ query: '(min-width: 1920px)' })
    return (
        <>
            {
                isPc ?
                    <main className="mainTabla pb-5">
                        <div>
                            <div className="tablaHeadPas">
                                <div class="d-flex">
                                    <p className="tituloTabla1366 center ">NRO</p>
                                    {sortA === 1 ?
                                        <button style={botonSort} onClick={(e) => setSortA(2)}><i class="bi bi-sort-up" style={iconSort} ></i></button>
                                        : (sortA === 2 ?
                                            <button style={botonSort} onClick={(e) => setSortA(1)}><i class="bi bi-sort-down" style={iconSort} ></i></button>
                                            :
                                            <button style={botonSort} onClick={(e) => setSortA(1)}><i class="bi bi-filter" style={iconSort} ></i></button>
                                        )}
                                </div>
                                <div class="d-flex">
                                    <p className="tituloTabla1366 center">PATENTE</p>
                                    {sortA === 3 ?
                                        <button style={botonSort} onClick={(e) => setSortA(4)}><i class="bi bi-sort-up" style={iconSort} ></i></button>
                                        : (sortA === 4 ?
                                            <button style={botonSort} onClick={(e) => setSortA(3)}><i class="bi bi-sort-down" style={iconSort} ></i></button>
                                            :
                                            <button style={botonSort} onClick={(e) => setSortA(3)}><i class="bi bi-filter" style={iconSort} ></i></button>
                                        )}
                                </div>
                                <div class="d-flex">
                                    <p className="tituloTabla1366 center ">F STRO</p>
                                    {sortA === 5 ?
                                        <button style={botonSort} onClick={(e) => setSortA(6)}><i class="bi bi-sort-up" style={iconSort} ></i></button>
                                        : (sortA === 6 ?
                                            <button style={botonSort} onClick={(e) => setSortA(5)}><i class="bi bi-sort-down" style={iconSort} ></i></button>
                                            :
                                            <button style={botonSort} onClick={(e) => setSortA(5)}><i class="bi bi-filter" style={iconSort} ></i></button>
                                        )}
                                </div>
                                <div class="d-flex">
                                    <p className="tituloTabla1366 center ">CIA</p>
                                    {sortA === 7 ?
                                        <button style={botonSort} onClick={(e) => setSortA(8)}><i class="bi bi-sort-up" style={iconSort} ></i></button>
                                        : (sortA === 8 ?
                                            <button style={botonSort} onClick={(e) => setSortA(7)}><i class="bi bi-sort-down" style={iconSort} ></i></button>
                                            :
                                            <button style={botonSort} onClick={(e) => setSortA(7)}><i class="bi bi-filter" style={iconSort} ></i></button>
                                        )}
                                </div>
                                <div class="d-flex">
                                    <p className="tituloTabla1366 center">CLIENTE</p>
                                    {sortA === 9 ?
                                        <button style={botonSort} onClick={(e) => setSortA(10)}><i class="bi bi-sort-up" style={iconSort} ></i></button>
                                        : (sortA === 10 ?
                                            <button style={botonSort} onClick={(e) => setSortA(9)}><i class="bi bi-sort-down" style={iconSort} ></i></button>
                                            :
                                            <button style={botonSort} onClick={(e) => setSortA(9)}><i class="bi bi-filter" style={iconSort} ></i></button>
                                        )}
                                </div>
                                <div class="d-flex">
                                    <p className="titulo2Tabla1366 center ">MES ESTIMADO DE PAGO</p>
                                    {sortA === 11 ?
                                        <button style={botonSort} onClick={(e) => setSortA(12)}><i class="bi bi-sort-up" style={iconSort} ></i></button>
                                        : (sortA === 12 ?
                                            <button style={botonSort} onClick={(e) => setSortA(11)}><i class="bi bi-sort-down" style={iconSort} ></i></button>
                                            :
                                            <button style={botonSort} onClick={(e) => setSortA(11)}><i class="bi bi-filter" style={iconSort} ></i></button>
                                        )}
                                </div>
                                <div class="d-flex">
                                    <p className="tituloTabla1366 center">CLIENTE CONTACTADO</p>
                                </div>
                                <div class="d-flex">
                                    <p className="tituloTabla1366 center">ESTADO</p>
                                    {sortA === 13 ?
                                        <button style={botonSort} onClick={(e) => setSortA(14)}><i class="bi bi-sort-up" style={iconSort} ></i></button>
                                        : (sortA === 14 ?
                                            <button style={botonSort} onClick={(e) => setSortA(13)}><i class="bi bi-sort-down" style={iconSort} ></i></button>
                                            :
                                            <button style={botonSort} onClick={(e) => setSortA(13)}><i class="bi bi-filter" style={iconSort} ></i></button>
                                        )}
                                </div>
                            </div>
                            <div class="mt-2">
                                <div>{updatedList.slice(itemOffset, Number(itemOffset + 20)).map((expediente) => {
                                    return (
                                        <ExpedientePas
                                            expediente={expediente}
                                            key={expediente.id}
                                        />
                                    );
                                })}</div>
                            </div>
                        </div>
                        <div>
                            <div className="filtroTicketContainer me-1">
                                <div className="d-flex ps-3 pt-3">
                                    <div className="">
                                        <button onClick={(e) => filtrar()} style={btn}><img src={iconoFiltrar} style={img} alt="filtrar"></img>FILTRAR</button>
                                    </div>
                                    <div>
                                        <button onClick={(e) => vaciar()} style={btn}><img src={iconoGlobal} alt="vaciar" style={img}></img>VACIAR</button>
                                    </div>
                                </div>
                                <div className="me-3 ms-3 mt-3" onKeyDown={(e) => { if (e.key === 'Enter') { filtrar() } }}>
                                    <div class="">
                                        <input
                                            type="text"
                                            value={info.info}
                                            class="inputsDelFiltroSearch"
                                            placeholder="BUSQUEDA GENERAL"
                                            onChange={(e) =>
                                                setInfo((prevState) => ({
                                                    ...prevState,
                                                    info: e.target.value,
                                                }))
                                            }
                                        />
                                    </div>
                                    <hr className="me-3 ms-3 mt-3" />
                                    <div className="row mt-3">
                                        <div className="col">
                                            <h6 className="">Estado</h6>
                                            <MultiSelect
                                                className=""
                                                options={estadoInput}
                                                value={info.estado}
                                                onChange={(e) => {
                                                    setInfo((prevState) => ({
                                                        ...prevState,
                                                        estado: e,
                                                    }));
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col">
                                            <h6 className="">Compañia</h6>
                                            <MultiSelect
                                                className=""
                                                options={compInput.sort((a, b) => a.label.localeCompare(b.label))}
                                                value={info.comp}
                                                onChange={(e) => {
                                                    setInfo((prevState) => ({
                                                        ...prevState,
                                                        comp: e,
                                                    }));
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col">
                                            <h6 className="">Patente</h6>
                                            <input
                                                type="search"
                                                class="inputsDelFiltro"
                                                onChange={(e) =>
                                                    setInfo((prevState) => ({
                                                        ...prevState,
                                                        patente: e.target.value,
                                                    }))
                                                }
                                            />
                                        </div>
                                        <div className="col">
                                            <h6 className="">Cliente</h6>
                                            <input
                                                type="search"
                                                class="inputsDelFiltro"
                                                onChange={(e) =>
                                                    setInfo((prevState) => ({
                                                        ...prevState,
                                                        cliente: e.target.value,
                                                    }))
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-6">
                                            <h6 className="">Honorarios Cobrados</h6>
                                            <select
                                                className="inputsDelFiltro"
                                                onChange={(e) =>
                                                    setInfo((prevState) => ({
                                                        ...prevState,
                                                        honorariosCobrados: e.target.value,
                                                    }))
                                                }
                                                value={info.honorariosCobrados}
                                            >
                                                <option value={''}>Seleccionar</option>
                                                <option value={'SI'}>SI</option>
                                            </select>

                                        </div>
                                    </div>
                                    <div className="d-flex mt-5 center">
                                        <ReactPaginate
                                            forcePage={currentPage}
                                            className='react-paginate center'
                                            activeClassName={'item active '}
                                            breakClassName={'item break-me '}
                                            breakLabel={'...'}
                                            containerClassName={'pagination'}
                                            disabledClassName={'disabled-page'}
                                            marginPagesDisplayed={1}
                                            nextClassName={"item next "}
                                            nextLabel=">"
                                            onPageChange={handlePageClick}
                                            pageClassName={'item pagination-page '}
                                            pageRangeDisplayed={5}
                                            pageCount={pageCount}
                                            previousClassName={"item previous"}
                                            previousLabel="<"
                                        />
                                    </div>
                                    <div className="center mt-1">
                                        <h6>Cantidad de expedientes: {updatedList.length}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main >
                    : isMobile375 ?
                        <main style={{ paddingLeft: '3%', paddingRight: '3%' }}>
                            <h3 className="textoTablaMovil center" style={{ paddingTop: '13vh' }}>INGRESE EXPEDIENTE<br /> PATENTE O NOMBRE</h3>
                            <div className="center" style={{ marginTop: '3vh' }}>
                                <input type="text" className="center text-center inputBuscarTablaMovil" onChange={(e) => setInputMovil(e.target.value)} value={inputMovil} />
                            </div>
                            <div className="center" style={{ marginTop: '3vh' }}><button className="btnBuscarTablaMovil">BUSCAR</button></div>
                            <div style={{ marginTop: '3vh' }}>
                                <div className="row mb-3 pe-3 ps-3">
                                    <div className="textoHeaderTablaMovil col">NRO</div>
                                    <div className="textoHeaderTablaMovil col">PATENTE</div>
                                    <div className="textoHeaderTablaMovil col">F.STRO</div>
                                    <div className="textoHeaderTablaMovil col">COMPAÑIA</div>
                                    <div className="textoHeaderTablaMovil col">ESTADO</div>
                                </div>
                                {updatedMovil?.slice(0, 100).map(e => (
                                    <ExpedienteTablaMovil key={e.id} expediente={e} />
                                ))}

                            </div>
                        </main>
                        : isMobile412 ?
                            <main style={{ paddingLeft: '3%', paddingRight: '3%' }}>
                                <h3 className="textoTablaMovil center" style={{ paddingTop: '13vh' }}>INGRESE EXPEDIENTE<br /> PATENTE O NOMBRE</h3>
                                <div className="center" style={{ marginTop: '3vh' }}>
                                    <input type="text" className="center text-center inputBuscarTablaMovil" onChange={(e) => setInputMovil(e.target.value)} value={inputMovil} />
                                </div>
                                <div className="center" style={{ marginTop: '3vh' }}><button className="btnBuscarTablaMovil">BUSCAR</button></div>
                                <div style={{ marginTop: '3vh' }}>
                                    <div className="row mb-3 pe-3 ps-3">
                                        <div className="textoHeaderTablaMovil col">NRO</div>
                                        <div className="textoHeaderTablaMovil col">PATENTE</div>
                                        <div className="textoHeaderTablaMovil col">F.STRO</div>
                                        <div className="textoHeaderTablaMovil col">COMPAÑIA</div>
                                        <div className="textoHeaderTablaMovil col">ESTADO</div>
                                    </div>
                                    {updatedMovil?.slice(0, 100).map(e => (
                                        <ExpedienteTablaMovil key={e.id} expediente={e} />
                                    ))}

                                </div>
                            </main>
                            : isMobile480 ?
                                <main style={{ paddingLeft: '3%', paddingRight: '3%' }}>
                                    <h3 className="textoTablaMovil center" style={{ paddingTop: '13vh' }}>INGRESE EXPEDIENTE<br /> PATENTE O NOMBRE</h3>
                                    <div className="center" style={{ marginTop: '3vh' }}>
                                        <input type="text" className="center text-center inputBuscarTablaMovil" onChange={(e) => setInputMovil(e.target.value)} value={inputMovil} />
                                    </div>
                                    <div className="center" style={{ marginTop: '3vh' }}><button className="btnBuscarTablaMovil">BUSCAR</button></div>
                                    <div style={{ marginTop: '3vh' }}>
                                        <div className="row mb-3 pe-3 ps-3">
                                            <div className="textoHeaderTablaMovil col">NRO</div>
                                            <div className="textoHeaderTablaMovil col">PATENTE</div>
                                            <div className="textoHeaderTablaMovil col">F.STRO</div>
                                            <div className="textoHeaderTablaMovil col">COMPAÑIA</div>
                                            <div className="textoHeaderTablaMovil col">ESTADO</div>
                                        </div>
                                        {updatedMovil?.slice(0, 100).map(e => (
                                            <ExpedienteTablaMovil key={e.id} expediente={e} />
                                        ))}

                                    </div>
                                </main>
                                : isTablet768 ?
                                    <main style={{ paddingLeft: '3%', paddingRight: '3%' }}>
                                        <h3 className="textoTablaMovil center" style={{ paddingTop: '13vh' }}>INGRESE EXPEDIENTE<br /> PATENTE O NOMBRE</h3>
                                        <div className="center" style={{ marginTop: '3vh' }}>
                                            <input type="text" className="center text-center inputBuscarTablaMovil" onChange={(e) => setInputMovil(e.target.value)} value={inputMovil} />
                                        </div>
                                        <div className="center" style={{ marginTop: '3vh' }}><button className="btnBuscarTablaMovil">BUSCAR</button></div>
                                        <div style={{ marginTop: '3vh' }}>
                                            <div className="row mb-3 pe-3 ps-3">
                                                <div className="textoHeaderTablaMovil col">NRO</div>
                                                <div className="textoHeaderTablaMovil col">PATENTE</div>
                                                <div className="textoHeaderTablaMovil col">F.STRO</div>
                                                <div className="textoHeaderTablaMovil col">COMPAÑIA</div>
                                                <div className="textoHeaderTablaMovil col">ESTADO</div>
                                            </div>
                                            {updatedMovil?.slice(0, 100).map(e => (
                                                <ExpedienteTablaMovil key={e.id} expediente={e} />
                                            ))}
                                        </div>
                                    </main>
                                    : <main className="mainTabla pb-5">
                                        <div>
                                            <div className="tablaHeadPas">
                                                <div class="d-flex">
                                                    <p className="tituloTabla1366 center ">NRO</p>
                                                    {sortA === 1 ?
                                                        <button style={botonSort} onClick={(e) => setSortA(2)}><i class="bi bi-sort-up" style={iconSort} ></i></button>
                                                        : (sortA === 2 ?
                                                            <button style={botonSort} onClick={(e) => setSortA(1)}><i class="bi bi-sort-down" style={iconSort} ></i></button>
                                                            :
                                                            <button style={botonSort} onClick={(e) => setSortA(1)}><i class="bi bi-filter" style={iconSort} ></i></button>
                                                        )}
                                                </div>
                                                <div class="d-flex">
                                                    <p className="tituloTabla1366 center">PATENTE</p>
                                                    {sortA === 3 ?
                                                        <button style={botonSort} onClick={(e) => setSortA(4)}><i class="bi bi-sort-up" style={iconSort} ></i></button>
                                                        : (sortA === 4 ?
                                                            <button style={botonSort} onClick={(e) => setSortA(3)}><i class="bi bi-sort-down" style={iconSort} ></i></button>
                                                            :
                                                            <button style={botonSort} onClick={(e) => setSortA(3)}><i class="bi bi-filter" style={iconSort} ></i></button>
                                                        )}
                                                </div>
                                                <div class="d-flex">
                                                    <p className="tituloTabla1366 center ">F STRO</p>
                                                    {sortA === 5 ?
                                                        <button style={botonSort} onClick={(e) => setSortA(6)}><i class="bi bi-sort-up" style={iconSort} ></i></button>
                                                        : (sortA === 6 ?
                                                            <button style={botonSort} onClick={(e) => setSortA(5)}><i class="bi bi-sort-down" style={iconSort} ></i></button>
                                                            :
                                                            <button style={botonSort} onClick={(e) => setSortA(5)}><i class="bi bi-filter" style={iconSort} ></i></button>
                                                        )}
                                                </div>
                                                <div class="d-flex">
                                                    <p className="tituloTabla1366 center ">CIA</p>
                                                    {sortA === 7 ?
                                                        <button style={botonSort} onClick={(e) => setSortA(8)}><i class="bi bi-sort-up" style={iconSort} ></i></button>
                                                        : (sortA === 8 ?
                                                            <button style={botonSort} onClick={(e) => setSortA(7)}><i class="bi bi-sort-down" style={iconSort} ></i></button>
                                                            :
                                                            <button style={botonSort} onClick={(e) => setSortA(7)}><i class="bi bi-filter" style={iconSort} ></i></button>
                                                        )}
                                                </div>
                                                <div class="d-flex">
                                                    <p className="tituloTabla1366 center">CLIENTE</p>
                                                    {sortA === 9 ?
                                                        <button style={botonSort} onClick={(e) => setSortA(10)}><i class="bi bi-sort-up" style={iconSort} ></i></button>
                                                        : (sortA === 10 ?
                                                            <button style={botonSort} onClick={(e) => setSortA(9)}><i class="bi bi-sort-down" style={iconSort} ></i></button>
                                                            :
                                                            <button style={botonSort} onClick={(e) => setSortA(9)}><i class="bi bi-filter" style={iconSort} ></i></button>
                                                        )}
                                                </div>
                                                <div class="d-flex">
                                                    <p className="titulo2Tabla1366 center ">MES ESTIMADO DE PAGO</p>
                                                    {sortA === 11 ?
                                                        <button style={botonSort} onClick={(e) => setSortA(12)}><i class="bi bi-sort-up" style={iconSort} ></i></button>
                                                        : (sortA === 12 ?
                                                            <button style={botonSort} onClick={(e) => setSortA(11)}><i class="bi bi-sort-down" style={iconSort} ></i></button>
                                                            :
                                                            <button style={botonSort} onClick={(e) => setSortA(11)}><i class="bi bi-filter" style={iconSort} ></i></button>
                                                        )}
                                                </div>
                                                <div class="d-flex">
                                                    <p className="tituloTabla1366 center">CLIENTE CONTACTADO</p>
                                                </div>
                                                <div class="d-flex">
                                                    <p className="tituloTabla1366 center">ESTADO</p>
                                                    {sortA === 13 ?
                                                        <button style={botonSort} onClick={(e) => setSortA(14)}><i class="bi bi-sort-up" style={iconSort} ></i></button>
                                                        : (sortA === 14 ?
                                                            <button style={botonSort} onClick={(e) => setSortA(13)}><i class="bi bi-sort-down" style={iconSort} ></i></button>
                                                            :
                                                            <button style={botonSort} onClick={(e) => setSortA(13)}><i class="bi bi-filter" style={iconSort} ></i></button>
                                                        )}
                                                </div>
                                            </div>
                                            <div class="mt-2">
                                                <div>{updatedList.slice(itemOffset, Number(itemOffset + 20)).map((expediente) => {
                                                    return (
                                                        <ExpedientePas
                                                            expediente={expediente}
                                                            key={expediente.id}
                                                        />
                                                    );
                                                })}</div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="filtroTicketContainer me-1">
                                                <div className="d-flex ps-3 pt-3">
                                                    <div className="">
                                                        <button onClick={(e) => filtrar()} style={btn}><img src={iconoFiltrar} style={img} alt="filtrar"></img>FILTRAR</button>
                                                    </div>
                                                    <div>
                                                        <button onClick={(e) => vaciar()} style={btn}><img src={iconoGlobal} alt="vaciar" style={img}></img>VACIAR</button>
                                                    </div>
                                                </div>
                                                <div className="me-3 ms-3 mt-3" onKeyDown={(e) => { if (e.key === 'Enter') { filtrar() } }}>
                                                    <div class="">
                                                        <input
                                                            type="text"
                                                            value={info.info}
                                                            class="inputsDelFiltroSearch"
                                                            placeholder="BUSQUEDA GENERAL"
                                                            onChange={(e) =>
                                                                setInfo((prevState) => ({
                                                                    ...prevState,
                                                                    info: e.target.value,
                                                                }))
                                                            }
                                                        />
                                                    </div>
                                                    <hr className="me-3 ms-3 mt-3" />
                                                    <div className="row mt-3">
                                                        <div className="col">
                                                            <h6 className="">Estado</h6>
                                                            <MultiSelect
                                                                className=""
                                                                options={estadoInput}
                                                                value={info.estado}
                                                                onChange={(e) => {
                                                                    setInfo((prevState) => ({
                                                                        ...prevState,
                                                                        estado: e,
                                                                    }));
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-3">
                                                        <div className="col">
                                                            <h6 className="">Compañia</h6>
                                                            <MultiSelect
                                                                className=""
                                                                options={compInput.sort((a, b) => a.label.localeCompare(b.label))}
                                                                value={info.comp}
                                                                onChange={(e) => {
                                                                    setInfo((prevState) => ({
                                                                        ...prevState,
                                                                        comp: e,
                                                                    }));
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-3">
                                                        <div className="col">
                                                            <h6 className="">Patente</h6>
                                                            <input
                                                                type="search"
                                                                class="inputsDelFiltro"
                                                                onChange={(e) =>
                                                                    setInfo((prevState) => ({
                                                                        ...prevState,
                                                                        patente: e.target.value,
                                                                    }))
                                                                }
                                                            />
                                                        </div>
                                                        <div className="col">
                                                            <h6 className="">Cliente</h6>
                                                            <input
                                                                type="search"
                                                                class="inputsDelFiltro"
                                                                onChange={(e) =>
                                                                    setInfo((prevState) => ({
                                                                        ...prevState,
                                                                        cliente: e.target.value,
                                                                    }))
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-3">
                                                        <div className="col-6">
                                                            <h6 className="">Honorarios Cobrados</h6>
                                                            <select
                                                                className="inputsDelFiltro"
                                                                onChange={(e) =>
                                                                    setInfo((prevState) => ({
                                                                        ...prevState,
                                                                        honorariosCobrados: e.target.value,
                                                                    }))
                                                                }
                                                                value={info.honorariosCobrados}
                                                            >
                                                                <option value={''}>Seleccionar</option>
                                                                <option value={'SI'}>SI</option>
                                                            </select>

                                                        </div>
                                                    </div>
                                                    <div className="d-flex mt-5 center">
                                                        <ReactPaginate
                                                            forcePage={currentPage}
                                                            className='react-paginate center'
                                                            activeClassName={'item active '}
                                                            breakClassName={'item break-me '}
                                                            breakLabel={'...'}
                                                            containerClassName={'pagination'}
                                                            disabledClassName={'disabled-page'}
                                                            marginPagesDisplayed={1}
                                                            nextClassName={"item next "}
                                                            nextLabel=">"
                                                            onPageChange={handlePageClick}
                                                            pageClassName={'item pagination-page '}
                                                            pageRangeDisplayed={5}
                                                            pageCount={pageCount}
                                                            previousClassName={"item previous"}
                                                            previousLabel="<"
                                                        />
                                                    </div>
                                                    <div className="center mt-1">
                                                        <h6>Cantidad de expedientes: {updatedList.length}</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </main >}
        </>
    );
}
