import axios from "axios";
import { useState, useEffect } from "react";
import Expediente from "../components/Expedientes/Expediente";
import "./index.css";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";
import { MultiSelect } from "react-multi-select-component";
import ReactPaginate from 'react-paginate';
import Excel from "./Excel";
import './paginate.css'
import iconoFiltrar from "../images/iconoFiltrar.png";
import iconoGlobal from "../images/iconoGlobal.png";
import Select from 'react-select';
import { useMediaQuery } from 'react-responsive'
import ExpedienteTablaMovil from "../components/TablaMovil/ExpedienteTablaMovil";
export default function TablaEmpleados() {
    const navigate = useNavigate();
    const a = localStorage.getItem('user');
    if (a === null) {
        navigate('/login')
    }
    const [expedientes, setExpedientes] = useState([]);
    const [productores, setProductores] = useState([]);
    const [companias, setCompanias] = useState([]);
    const [preCarga, setPreCarga] = useState(false)
    useEffect(() => {
        axios.get("https://api.tuveunchoque.com.ar/api/gerencia/expedientes/tablas").then((res) => {
            setExpedientes(res.data.reverse());
            setPreCarga(true)
        });
        axios.get("https://api.tuveunchoque.com.ar/api/nuevosUsuarios/pasParaFiltros").then((res) => {
            setProductores(res.data);
        });
        axios.get("https://api.tuveunchoque.com.ar/api/gerencia/companias").then((res) => {
            setCompanias(res.data);
        });
    }, []);
    const estadoInput = [
        { label: "RECEPCIONADO", value: "RECEPCIONADO" },
        { label: "SIN DENUNCIA", value: "SIN DENUNCIA" },
        { label: "ARMADO PRELIMINAR", value: "ARMADO PRELIMINAR" },
        { label: "RECLAMO PRESENTADO", value: "RECLAMO PRESENTADO" },
        { label: "ACEPTACION", value: "ACEPTACION" },
        { label: "EN PROCESO DE PAGO", value: "EN PROCESO DE PAGO" },
        { label: "COBRADO", value: "COBRADO" },
        { label: "MEDIACION", value: "MEDIACION" },
        { label: "CERRADO", value: "CERRADO" },
    ];
    const nivelInput = [
        { label: "0", value: "0" },
        { label: "1", value: "1" },
        { label: "2", value: "2" },
        { label: "3", value: "3" },
    ];
    const fechaInput = [
        { label: "HOY", value: "HOY" },
        { label: "MAÑANA", value: "MAÑANA" },
        { label: "VENCIDOS", value: "VENCIDOS" },
    ];
    const asignadoInput = [
        { label: "AGOSTINA", value: "AGOSTINA" },
        { label: "CATALINA", value: "CATALINA" },
        { label: "DANIELA", value: "DANIELA" },
        { label: "FRANCO", value: "FRANCO" },
        { label: "JUAN", value: "JUAN" },
        { label: "SOFIA", value: "SOFIA" },
        { label: "MICAELA", value: 'MICAELA' },
        { label: "FEDERICO", value: 'FEDERICO' },
        { label: "FACUNDO", value: 'FACUNDO' },
        { label: "LUCAS", value: 'LUCAS' },
        { label: "GONZALO", value: 'GONZALO' },
        { label: "", value: null }
    ];
    const compInput = companias.map((c) => {
        return { value: c.compania, label: c.compania };
    });
    const proInput = productores.map((p) => {
        return { value: p.nombre, label: p.nombre };
    });
    let updatedList = expedientes;
    const [cookies, setCookie, removeCookie] = useCookies(["user"]);
    const cook = () => {
        if (!cookies.user) {
            navigate("/login");
        }
    };
    cook();
    const [infoTabla, setInfoTabla, removeInfoTabla] = useCookies(["tabla"]);
    const [info, setInfo] = useState({
        info: infoTabla.tabla.info,
        pas: infoTabla.tabla.pas,
        comp: infoTabla.tabla.comp,
        estado: infoTabla.tabla.estado,
        nivel: infoTabla.tabla.nivel,
        fecha: infoTabla.tabla.fecha,
        asignado: infoTabla.tabla.asignado,
        estadoPrueba: infoTabla.tabla.estadoPrueba,
        patente: infoTabla.tabla.patente,
        cliente: infoTabla.tabla.cliente,
    });
    const conFiltro = () => {
        if (infoTabla.tabla.info) {
            updatedList = updatedList.filter((expediente) =>
                JSON.stringify(expediente.nro).includes(infoTabla.tabla.info) ||
                JSON.stringify(expediente.polizaRequerido).replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '').includes(infoTabla.tabla.info) ||
                JSON.stringify(expediente.nroReclamoCompaniaRequerido).replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '').includes(infoTabla.tabla.info) ||
                JSON.stringify(expediente.polizaRequerido).replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '').substring(-4).includes(infoTabla.tabla.info) ||
                JSON.stringify(expediente.nroReclamoCompaniaRequerido).replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '').substring(-4).includes(infoTabla.tabla.info) ||
                JSON.stringify(expediente.dominioCliente).toLowerCase().includes(JSON.stringify(infoTabla.tabla.info).toLowerCase().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')) ||
                JSON.stringify(expediente.productorAsociado).toLowerCase().includes(JSON.stringify(infoTabla.tabla.info).toLowerCase().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')) ||
                JSON.stringify(expediente.nombre).toLowerCase().includes(JSON.stringify(infoTabla.tabla.info).toLowerCase().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')) ||
                JSON.stringify(expediente.compania).toLowerCase().includes(JSON.stringify(infoTabla.tabla.info).toLowerCase().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')) ||
                JSON.stringify(expediente.patenteRequerido).toLowerCase().includes(JSON.stringify(infoTabla.tabla.info).toLowerCase().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')) ||
                JSON.stringify(expediente.nroSeguimientoCompaniaRequerido).toLowerCase().includes(JSON.stringify(infoTabla.tabla.info).toLowerCase().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''))
            );
        }
        if (infoTabla.tabla.pas.length > 0) {
            updatedList = updatedList.filter((expediente) => {
                return infoTabla.tabla.pas.some((ele) => {
                    return ele.value === expediente.productorAsociado;
                });
            });
        }
        if (infoTabla.tabla.comp.length > 0) {
            updatedList = updatedList.filter((expediente) => {
                return infoTabla.tabla.comp.some((ele) => {
                    return ele.value === expediente.compania;
                });
            });
        }
        if (infoTabla.tabla.estado.length > 0) {
            updatedList = updatedList.filter((expediente) => {
                return infoTabla.tabla.estado.some((ele) => {
                    return ele.value === expediente.estado;
                });
            });
        }
        if (infoTabla.tabla.asignado.length > 0) {
            updatedList = updatedList.filter((expediente) => {
                return infoTabla.tabla.asignado.some((ele) => {
                    return ele.value === expediente.asignadoA;
                });
            });
        }
        if (infoTabla.tabla.nivel.length > 0) {
            updatedList = updatedList.filter((expediente) => {
                return infoTabla.tabla.nivel.some((ele) => {
                    return ele.value == expediente.nivel;
                });
            });
        }
        if (infoTabla.tabla.fecha.some((ele) => { return ele.value === "HOY"; })) {
            updatedList = updatedList.filter((e) => new Date(moment(e.fechaVencimiento, "DD/MM/YYYY").toDate()).toDateString() === new Date().toDateString());
        }
        if (infoTabla.tabla.fecha.some((ele) => { return ele.value === "MAÑANA"; })) {
            updatedList = updatedList.filter((e) => new Date(moment(e.fechaVencimiento, "DD/MM/YYYY").toDate()).toLocaleDateString() === new Date(+new Date().setHours(0, 0, 0, 0) + 86400000).toLocaleDateString());
        }
        if (infoTabla.tabla.fecha.some((ele) => { return ele.value === "VENCIDOS" })) {
            updatedList = updatedList.filter((e) => e.estado !== 'COBRADO' && e.estado !== 'CERRADO' && Date.parse(moment(e.fechaVencimiento, "DD/MM/YYYY").toDate()) < new Date().setHours(0, 0, 0, 0));
        }
        if (cookies.user.cargo === "PAS") {
            updatedList = updatedList.filter(
                (expediente) => expediente.productorAsociado === cookies.user?.nombre
            );
        }
    };
    conFiltro();
    const [abc, setAbc] = useState(20);
    const [bcd, setBcd] = useState(20);
    useEffect(() => {
        setCookie('tabla', JSON.stringify(info), { path: '/' });
    }, [info]);
    const filtrar = async () => {
        setAbc(20);
        setBcd(20);
    };
    const vaciar = async () => {
        setAbc(20);
        setBcd(20);
        setInfo({
            info: "",
            pas: [],
            comp: [],
            estado: [],
            nivel: [],
            fecha: [],
            asignado: [],
            patente: "",
        });
    };
    const btn = { border: 'none', background: 'none', fontSize: '12px', color: '#0089FF', fontWeight: '100' }
    const [sortA, setSortA] = useState(1)
    const sortFunctions = {
        1: (a, b) => b.nro - a.nro,
        2: (a, b) => a.nro - b.nro,
        3: (a, b) => a.nivel - b.nivel,
        4: (a, b) => b.nivel - a.nivel,
        5: (a, b) => (a.dominioCliente || '').localeCompare(b.dominioCliente || ''),
        6: (a, b) => (b.dominioCliente || '').localeCompare(a.dominioCliente || ''),
        7: (a, b) => (a.productorAsociado || '').localeCompare(b.productorAsociado || ''),
        8: (a, b) => (b.productorAsociado || '').localeCompare(a.productorAsociado || ''),
        9: (a, b) => new Date(b.fechaCambioEstado) - new Date(a.fechaCambioEstado),
        10: (a, b) => new Date(a.fechaCambioEstado) - new Date(b.fechaCambioEstado),
        11: (a, b) => (a.compania || '').localeCompare(b.compania || ''),
        12: (a, b) => (b.compania || '').localeCompare(a.compania || ''),
        13: (a, b) => (a.nombre || '').localeCompare(b.nombre || ''),
        14: (a, b) => (b.nombre || '').localeCompare(a.nombre || ''),
        15: (a, b) => new Date(b.fechaVencimiento) - new Date(a.fechaVencimiento),
        16: (a, b) => new Date(a.fechaVencimiento) - new Date(b.fechaVencimiento),
        17: (a, b) => new Date(b.ultimaMod) - new Date(a.ultimaMod),
        18: (a, b) => new Date(a.ultimaMod) - new Date(b.ultimaMod),
    };
    const sort = () => {
        const sortFn = sortFunctions[sortA];
        if (sortFn) {
            updatedList.sort(sortFn);
        }
    };
    sort()
    let itemsPerPage = 20
    const [itemOffset, setItemOffset] = useState(0);
    const endOffset = itemOffset + itemsPerPage;
    const currentItems = updatedList.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(updatedList.length / itemsPerPage);
    const [currentPage, setCurrentPage] = useState(0);
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % updatedList.length;
        setItemOffset(newOffset);
        setCurrentPage(event.selected);
    }
    useEffect(() => {
        setItemOffset(0);
        setCurrentPage(0);
    }, [updatedList.length]);
    const img = { heigth: '44px', width: '44px', display: 'block' }
    const aka = { marginLeft: '16px', marginRight: '16px' }
    const botonSort = { border: 'none', background: 'none' }
    const iconSort = { color: '#FFFFFF' }


    let updatedMovil = expedientes;
    const [inputMovil, setInputMovil] = useState('')
    const filtroMovil = () => {
        if (inputMovil) {
            updatedMovil = updatedMovil.filter((expediente) =>
                JSON.stringify(expediente.nro).includes(inputMovil) ||
                JSON.stringify(expediente.dominioCliente).toLowerCase().includes(JSON.stringify(inputMovil).toLowerCase().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')) ||
                JSON.stringify(expediente.nombre).toLowerCase().includes(JSON.stringify(inputMovil).toLowerCase().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''))
            );
        }
    };
    filtroMovil();

    const isMobile375 = useMediaQuery({ query: '(max-width: 375px)' });
    const isMobile412 = useMediaQuery({ query: '(min-width: 376px) and (max-width: 412px)' });
    const isMobile480 = useMediaQuery({ query: '(min-width: 413px) and (max-width: 480px)' });
    const isTablet768 = useMediaQuery({ query: '(min-width: 480px) and (max-width: 900px)' });
    const isPc = useMediaQuery({ query: '(min-width: 900px) and (max-width: 2920px)' });
    const isPcBig = useMediaQuery({ query: '(min-width: 2920px)' })
    return (
        <>
            {isPc ?
                <main className="mainTabla pb-5">
                    <div>
                        {preCarga === true ? (updatedList.length > 0 ?
                            <>
                                <div className="tablaHead">
                                    <div class="d-flex">
                                        <p className="tituloTabla1366 center">NRO</p>
                                        {sortA === 1 ?
                                            <button style={botonSort} onClick={(e) => setSortA(2)}><i class="bi bi-sort-up" style={iconSort} ></i></button>
                                            : (sortA === 2 ?
                                                <button style={botonSort} onClick={(e) => setSortA(1)}><i class="bi bi-sort-down" style={iconSort} ></i></button>
                                                :
                                                <button style={botonSort} onClick={(e) => setSortA(1)}><i class="bi bi-filter" style={iconSort} ></i></button>
                                            )}
                                    </div>
                                    <div class="d-flex">
                                        <p className="tituloTabla1366 center">PATENTE</p>
                                        {sortA === 3 ?
                                            <button style={botonSort} onClick={(e) => setSortA(4)}><i class="bi bi-sort-up" style={iconSort} ></i></button>
                                            : (sortA === 4 ?
                                                <button style={botonSort} onClick={(e) => setSortA(3)}><i class="bi bi-sort-down" style={iconSort} ></i></button>
                                                :
                                                <button style={botonSort} onClick={(e) => setSortA(3)}><i class="bi bi-filter" style={iconSort} ></i></button>
                                            )}

                                    </div>
                                    <div class="d-flex">
                                        <p className="tituloTabla1366 center">PAS</p>
                                        {sortA === 5 ?
                                            <button style={botonSort} onClick={(e) => setSortA(6)}><i class="bi bi-sort-up" style={iconSort} ></i></button>
                                            : (sortA === 6 ?
                                                <button style={botonSort} onClick={(e) => setSortA(5)}><i class="bi bi-sort-down" style={iconSort} ></i></button>
                                                :
                                                <button style={botonSort} onClick={(e) => setSortA(5)}><i class="bi bi-filter" style={iconSort} ></i></button>
                                            )}
                                    </div>
                                    <div class="d-flex">
                                        <p className="tituloTabla1366 center">F.C ESTADO</p>
                                        {sortA === 7 ?
                                            <button style={botonSort} onClick={(e) => setSortA(8)}><i class="bi bi-sort-up" style={iconSort} ></i></button>
                                            : (sortA === 8 ?
                                                <button style={botonSort} onClick={(e) => setSortA(7)}><i class="bi bi-sort-down" style={iconSort} ></i></button>
                                                :
                                                <button style={botonSort} onClick={(e) => setSortA(7)}><i class="bi bi-filter" style={iconSort} ></i></button>
                                            )}
                                    </div>
                                    <div class="d-flex">
                                        <p className="tituloTabla1366 center">CIA</p>
                                        {sortA === 9 ?
                                            <button style={botonSort} onClick={(e) => setSortA(10)}><i class="bi bi-sort-up" style={iconSort} ></i></button>
                                            : (sortA === 10 ?
                                                <button style={botonSort} onClick={(e) => setSortA(9)}><i class="bi bi-sort-down" style={iconSort} ></i></button>
                                                :
                                                <button style={botonSort} onClick={(e) => setSortA(9)}><i class="bi bi-filter" style={iconSort} ></i></button>
                                            )}
                                    </div>
                                    <div class="d-flex">
                                        <p className="tituloTabla1366 center">CLIENTE</p>
                                        {sortA === 11 ?
                                            <button style={botonSort} onClick={(e) => setSortA(12)}><i class="bi bi-sort-up" style={iconSort} ></i></button>
                                            : (sortA === 12 ?
                                                <button style={botonSort} onClick={(e) => setSortA(11)}><i class="bi bi-sort-down" style={iconSort} ></i></button>
                                                :
                                                <button style={botonSort} onClick={(e) => setSortA(11)}><i class="bi bi-filter" style={iconSort} ></i></button>
                                            )}
                                    </div>
                                    <div class="d-flex">
                                        <p className="tituloTabla1366 center">F VTO</p>
                                        {sortA === 13 ?
                                            <button style={botonSort} onClick={(e) => setSortA(14)}><i class="bi bi-sort-up" style={iconSort} ></i></button>
                                            : (sortA === 14 ?
                                                <button style={botonSort} onClick={(e) => setSortA(13)}><i class="bi bi-sort-down" style={iconSort} ></i></button>
                                                :
                                                <button style={botonSort} onClick={(e) => setSortA(13)}><i class="bi bi-filter" style={iconSort} ></i></button>
                                            )}
                                    </div>
                                    <div class="d-flex">
                                        <p className="tituloTabla1366 center">NIVEL</p>
                                        {sortA === 15 ?
                                            <button style={botonSort} onClick={(e) => setSortA(16)}><i class="bi bi-sort-up" style={iconSort} ></i></button>
                                            : (sortA === 16 ?
                                                <button style={botonSort} onClick={(e) => setSortA(15)}><i class="bi bi-sort-down" style={iconSort} ></i></button>
                                                :
                                                <button style={botonSort} onClick={(e) => setSortA(15)}><i class="bi bi-filter" style={iconSort} ></i></button>
                                            )}
                                    </div>
                                    <div class="d-flex">
                                        <p className="tituloTabla1366 center">ESTADO</p>
                                        {sortA === 17 ?
                                            <button style={botonSort} onClick={(e) => setSortA(18)}><i class="bi bi-sort-up" style={iconSort} ></i></button>
                                            : (sortA === 18 ?
                                                <button style={botonSort} onClick={(e) => setSortA(17)}><i class="bi bi-sort-down" style={iconSort} ></i></button>
                                                :
                                                <button style={botonSort} onClick={(e) => setSortA(17)}><i class="bi bi-filter" style={iconSort} ></i></button>
                                            )}
                                    </div>
                                </div>
                                <div class=" mt-2">
                                    <>{updatedList.slice(itemOffset, Number(itemOffset + 20)).map((expediente) => {
                                        return (
                                            <Expediente
                                                expediente={expediente}
                                                key={expediente.id}
                                            />
                                        );
                                    })}</>
                                </div>
                            </>
                            :
                            <div className="table-responsive scrollbar divTabla"><h2 className="center letra mt-5">SIN RESULTADOS</h2></div>
                        ) : <div className="center mt-5 pt-5"><div class="spinner-border text-primary center" role="status"></div>
                        </div>}
                        <div className="center mt-5">
                            <Excel data={updatedList} />
                        </div>
                    </div>
                    <div className="">
                        <div className="filtroTicketContainer me-1">
                            <div className="d-flex ps-3 pt-3">
                                <div className="">
                                    <button onClick={(e) => filtrar()} style={btn}><img src={iconoFiltrar} alt="filtrar" style={img}></img>FILTRAR</button>
                                </div>
                                <div>
                                    <button onClick={(e) => vaciar()} style={btn}><img src={iconoGlobal} alt="vaciar" style={img}></img>VACIAR</button>
                                </div>
                            </div>
                            <form className="me-3 ms-3 mt-3" onKeyDown={(e) => { if (e.key === 'Enter') { filtrar() } }}>
                                <div class="">
                                    <input
                                        placeholder="BUSQUEDA GENERAL"
                                        type="text"
                                        value={info.info}
                                        class="inputsDelFiltroSearch"
                                        onChange={(e) =>
                                            setInfo((prevState) => ({
                                                ...prevState,
                                                info: e.target.value,
                                            }))
                                        }
                                    />
                                </div>
                                <div className="row mt-3">
                                    <div className="col">
                                        <h6 className="subtituloDelFiltro m-0">Asignado</h6>
                                        <MultiSelect
                                            options={asignadoInput.sort((a, b) => a.label.localeCompare(b.label))}
                                            value={info.asignado}
                                            onChange={(e) => {
                                                setInfo((prevState) => ({
                                                    ...prevState,
                                                    asignado: e,
                                                }));
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-6">
                                        <h6 className="subtituloDelFiltro m-0">Estado</h6>
                                        <MultiSelect
                                            className=""
                                            options={estadoInput}
                                            value={info.estado}
                                            onChange={(e) => {
                                                setInfo((prevState) => ({
                                                    ...prevState,
                                                    estado: e,
                                                }));
                                            }}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <h6 className="subtituloDelFiltro m-0">Nivel</h6>
                                        <MultiSelect
                                            className=""
                                            options={nivelInput}
                                            value={info.nivel}
                                            onChange={(e) => {
                                                setInfo((prevState) => ({
                                                    ...prevState,
                                                    nivel: e,
                                                }));
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col">
                                        <h6 className="subtituloDelFiltro m-0">Vencimiento</h6>
                                        <MultiSelect
                                            className=""
                                            options={fechaInput}
                                            value={info.fecha}
                                            onChange={(e) => {
                                                setInfo((prevState) => ({
                                                    ...prevState,
                                                    fecha: e,
                                                }));
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col">
                                        <h6 className="subtituloDelFiltro m-0">Compañias</h6>
                                        <MultiSelect
                                            className=""
                                            options={compInput.sort((a, b) => a.label.localeCompare(b.label))}
                                            value={info.comp}
                                            onChange={(e) => {
                                                setInfo((prevState) => ({
                                                    ...prevState,
                                                    comp: e,
                                                }));
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col">
                                        <h6 className="subtituloDelFiltro m-0">Productores</h6>
                                        <MultiSelect
                                            className=""
                                            options={proInput.sort((a, b) => a.label.localeCompare(b.label))}
                                            value={info.pas}
                                            onChange={(e) => {
                                                setInfo((prevState) => ({
                                                    ...prevState,
                                                    pas: e,
                                                }));
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="mt-4 center">
                                    <ReactPaginate
                                        forcePage={currentPage}
                                        className='center p-0'
                                        activeClassName={'item active'}
                                        breakClassName={'item break-me'}
                                        breakLabel={'...'}
                                        marginPagesDisplayed={1}
                                        onPageChange={handlePageClick}
                                        pageClassName={'item pagination-page'}
                                        pageRangeDisplayed={5}
                                        pageCount={pageCount}
                                    />
                                </div>
                                <h6 className={info.vista ? 'center' : 'center'}>Cantidad de expedientes: {updatedList.length}</h6>
                            </form>
                        </div>
                    </div>
                </main>
                : isMobile375 ?
                    <main style={{ paddingLeft: '3%', paddingRight: '3%' }}>
                        <h3 className="textoTablaMovil center" style={{ paddingTop: '13vh' }}>INGRESE EXPEDIENTE<br /> PATENTE O NOMBRE</h3>
                        <div className="center" style={{ marginTop: '3vh' }}>
                            <input type="text" className="center text-center inputBuscarTablaMovil" onChange={(e) => setInputMovil(e.target.value)} value={inputMovil} />
                        </div>
                        <div className="center" style={{ marginTop: '3vh' }}><button className="btnBuscarTablaMovil">BUSCAR</button></div>
                        <div style={{ marginTop: '3vh' }}>
                            <div className="row mb-3 pe-3 ps-3">
                                <div className="textoHeaderTablaMovil col">NRO</div>
                                <div className="textoHeaderTablaMovil col">PATENTE</div>
                                <div className="textoHeaderTablaMovil col">F.STRO</div>
                                <div className="textoHeaderTablaMovil col">COMPAÑIA</div>
                                <div className="textoHeaderTablaMovil col">ESTADO</div>
                            </div>
                            {updatedMovil?.slice(0, 100).map(e => (
                                <ExpedienteTablaMovil key={e.id} expediente={e} />
                            ))}

                        </div>
                    </main>
                    : isMobile412 ?
                        <main style={{ paddingLeft: '3%', paddingRight: '3%' }}>
                            <h3 className="textoTablaMovil center" style={{ paddingTop: '13vh' }}>INGRESE EXPEDIENTE<br /> PATENTE O NOMBRE</h3>
                            <div className="center" style={{ marginTop: '3vh' }}>
                                <input type="text" className="center text-center inputBuscarTablaMovil" onChange={(e) => setInputMovil(e.target.value)} value={inputMovil} />
                            </div>
                            <div className="center" style={{ marginTop: '3vh' }}><button className="btnBuscarTablaMovil">BUSCAR</button></div>
                            <div style={{ marginTop: '3vh' }}>
                                <div className="row mb-3 pe-3 ps-3">
                                    <div className="textoHeaderTablaMovil col">NRO</div>
                                    <div className="textoHeaderTablaMovil col">PATENTE</div>
                                    <div className="textoHeaderTablaMovil col">F.STRO</div>
                                    <div className="textoHeaderTablaMovil col">COMPAÑIA</div>
                                    <div className="textoHeaderTablaMovil col">ESTADO</div>
                                </div>
                                {updatedMovil?.slice(0, 100).map(e => (
                                    <ExpedienteTablaMovil key={e.id} expediente={e} />
                                ))}

                            </div>
                        </main>
                        : isMobile480 ?
                            <main style={{ paddingLeft: '3%', paddingRight: '3%' }}>
                                <h3 className="textoTablaMovil center" style={{ paddingTop: '13vh' }}>INGRESE EXPEDIENTE<br /> PATENTE O NOMBRE</h3>
                                <div className="center" style={{ marginTop: '3vh' }}>
                                    <input type="text" className="center text-center inputBuscarTablaMovil" onChange={(e) => setInputMovil(e.target.value)} value={inputMovil} />
                                </div>
                                <div className="center" style={{ marginTop: '3vh' }}><button className="btnBuscarTablaMovil">BUSCAR</button></div>
                                <div style={{ marginTop: '3vh' }}>
                                    <div className="row mb-3 pe-3 ps-3">
                                        <div className="textoHeaderTablaMovil col">NRO</div>
                                        <div className="textoHeaderTablaMovil col">PATENTE</div>
                                        <div className="textoHeaderTablaMovil col">F.STRO</div>
                                        <div className="textoHeaderTablaMovil col">COMPAÑIA</div>
                                        <div className="textoHeaderTablaMovil col">ESTADO</div>
                                    </div>
                                    {updatedMovil?.slice(0, 100).map(e => (
                                        <ExpedienteTablaMovil key={e.id} expediente={e} />
                                    ))}

                                </div>
                            </main>
                            : isTablet768 ?
                                <main style={{ paddingLeft: '3%', paddingRight: '3%' }}>
                                    <h3 className="textoTablaMovil center" style={{ paddingTop: '13vh' }}>INGRESE EXPEDIENTE<br /> PATENTE O NOMBRE</h3>
                                    <div className="center" style={{ marginTop: '3vh' }}>
                                        <input type="text" className="center text-center inputBuscarTablaMovil" onChange={(e) => setInputMovil(e.target.value)} value={inputMovil} />
                                    </div>
                                    <div className="center" style={{ marginTop: '3vh' }}><button className="btnBuscarTablaMovil">BUSCAR</button></div>
                                    <div style={{ marginTop: '3vh' }}>
                                        <div className="row mb-3 pe-3 ps-3">
                                            <div className="textoHeaderTablaMovil col">NRO</div>
                                            <div className="textoHeaderTablaMovil col">PATENTE</div>
                                            <div className="textoHeaderTablaMovil col">F.STRO</div>
                                            <div className="textoHeaderTablaMovil col">COMPAÑIA</div>
                                            <div className="textoHeaderTablaMovil col">ESTADO</div>
                                        </div>
                                        {updatedMovil?.slice(0, 100).map(e => (
                                            <ExpedienteTablaMovil key={e.id} expediente={e} />
                                        ))}

                                    </div>
                                </main>
                                :
                                <main className="mainTabla pb-5">
                                    <div>
                                        {preCarga === true ? (updatedList.length > 0 ?
                                            <>
                                                <div className="tablaHead">
                                                    <div class="d-flex">
                                                        <p className="tituloTabla1366 center">NRO</p>
                                                        {sortA === 1 ?
                                                            <button style={botonSort} onClick={(e) => setSortA(2)}><i class="bi bi-sort-up" style={iconSort} ></i></button>
                                                            : (sortA === 2 ?
                                                                <button style={botonSort} onClick={(e) => setSortA(1)}><i class="bi bi-sort-down" style={iconSort} ></i></button>
                                                                :
                                                                <button style={botonSort} onClick={(e) => setSortA(1)}><i class="bi bi-filter" style={iconSort} ></i></button>
                                                            )}
                                                    </div>
                                                    <div class="d-flex">
                                                        <p className="tituloTabla1366 center">PATENTE</p>
                                                        {sortA === 3 ?
                                                            <button style={botonSort} onClick={(e) => setSortA(4)}><i class="bi bi-sort-up" style={iconSort} ></i></button>
                                                            : (sortA === 4 ?
                                                                <button style={botonSort} onClick={(e) => setSortA(3)}><i class="bi bi-sort-down" style={iconSort} ></i></button>
                                                                :
                                                                <button style={botonSort} onClick={(e) => setSortA(3)}><i class="bi bi-filter" style={iconSort} ></i></button>
                                                            )}

                                                    </div>
                                                    <div class="d-flex">
                                                        <p className="tituloTabla1366 center">PAS</p>
                                                        {sortA === 5 ?
                                                            <button style={botonSort} onClick={(e) => setSortA(6)}><i class="bi bi-sort-up" style={iconSort} ></i></button>
                                                            : (sortA === 6 ?
                                                                <button style={botonSort} onClick={(e) => setSortA(5)}><i class="bi bi-sort-down" style={iconSort} ></i></button>
                                                                :
                                                                <button style={botonSort} onClick={(e) => setSortA(5)}><i class="bi bi-filter" style={iconSort} ></i></button>
                                                            )}
                                                    </div>
                                                    <div class="d-flex">
                                                        <p className="tituloTabla1366 center">F.C ESTADO</p>
                                                        {sortA === 7 ?
                                                            <button style={botonSort} onClick={(e) => setSortA(8)}><i class="bi bi-sort-up" style={iconSort} ></i></button>
                                                            : (sortA === 8 ?
                                                                <button style={botonSort} onClick={(e) => setSortA(7)}><i class="bi bi-sort-down" style={iconSort} ></i></button>
                                                                :
                                                                <button style={botonSort} onClick={(e) => setSortA(7)}><i class="bi bi-filter" style={iconSort} ></i></button>
                                                            )}
                                                    </div>
                                                    <div class="d-flex">
                                                        <p className="tituloTabla1366 center">CIA</p>
                                                        {sortA === 9 ?
                                                            <button style={botonSort} onClick={(e) => setSortA(10)}><i class="bi bi-sort-up" style={iconSort} ></i></button>
                                                            : (sortA === 10 ?
                                                                <button style={botonSort} onClick={(e) => setSortA(9)}><i class="bi bi-sort-down" style={iconSort} ></i></button>
                                                                :
                                                                <button style={botonSort} onClick={(e) => setSortA(9)}><i class="bi bi-filter" style={iconSort} ></i></button>
                                                            )}
                                                    </div>
                                                    <div class="d-flex">
                                                        <p className="tituloTabla1366 center">CLIENTE</p>
                                                        {sortA === 11 ?
                                                            <button style={botonSort} onClick={(e) => setSortA(12)}><i class="bi bi-sort-up" style={iconSort} ></i></button>
                                                            : (sortA === 12 ?
                                                                <button style={botonSort} onClick={(e) => setSortA(11)}><i class="bi bi-sort-down" style={iconSort} ></i></button>
                                                                :
                                                                <button style={botonSort} onClick={(e) => setSortA(11)}><i class="bi bi-filter" style={iconSort} ></i></button>
                                                            )}
                                                    </div>
                                                    <div class="d-flex">
                                                        <p className="tituloTabla1366 center">F VTO</p>
                                                        {sortA === 13 ?
                                                            <button style={botonSort} onClick={(e) => setSortA(14)}><i class="bi bi-sort-up" style={iconSort} ></i></button>
                                                            : (sortA === 14 ?
                                                                <button style={botonSort} onClick={(e) => setSortA(13)}><i class="bi bi-sort-down" style={iconSort} ></i></button>
                                                                :
                                                                <button style={botonSort} onClick={(e) => setSortA(13)}><i class="bi bi-filter" style={iconSort} ></i></button>
                                                            )}
                                                    </div>
                                                    <div class="d-flex">
                                                        <p className="tituloTabla1366 center">NIVEL</p>
                                                        {sortA === 15 ?
                                                            <button style={botonSort} onClick={(e) => setSortA(16)}><i class="bi bi-sort-up" style={iconSort} ></i></button>
                                                            : (sortA === 16 ?
                                                                <button style={botonSort} onClick={(e) => setSortA(15)}><i class="bi bi-sort-down" style={iconSort} ></i></button>
                                                                :
                                                                <button style={botonSort} onClick={(e) => setSortA(15)}><i class="bi bi-filter" style={iconSort} ></i></button>
                                                            )}
                                                    </div>
                                                    <div class="d-flex">
                                                        <p className="tituloTabla1366 center">ESTADO</p>
                                                        {sortA === 17 ?
                                                            <button style={botonSort} onClick={(e) => setSortA(18)}><i class="bi bi-sort-up" style={iconSort} ></i></button>
                                                            : (sortA === 18 ?
                                                                <button style={botonSort} onClick={(e) => setSortA(17)}><i class="bi bi-sort-down" style={iconSort} ></i></button>
                                                                :
                                                                <button style={botonSort} onClick={(e) => setSortA(17)}><i class="bi bi-filter" style={iconSort} ></i></button>
                                                            )}
                                                    </div>
                                                </div>
                                                <div class=" mt-2">
                                                    <>{updatedList.slice(itemOffset, Number(itemOffset + 20)).map((expediente) => {
                                                        return (
                                                            <Expediente
                                                                expediente={expediente}
                                                                key={expediente.id}
                                                            />
                                                        );
                                                    })}</>
                                                </div>
                                            </>
                                            :
                                            <div className="table-responsive scrollbar divTabla"><h2 className="center letra mt-5">SIN RESULTADOS</h2></div>
                                        ) : <div className="center mt-5 pt-5"><div class="spinner-border text-primary center" role="status"></div>
                                        </div>}
                                        <div className="center mt-5">
                                            <Excel data={updatedList} />
                                        </div>
                                    </div>
                                    <div className="">
                                        <div className="filtroTicketContainer me-1">
                                            <div className="d-flex ps-3 pt-3">
                                                <div className="">
                                                    <button onClick={(e) => filtrar()} style={btn}><img src={iconoFiltrar} alt="filtrar" style={img}></img>FILTRAR</button>
                                                </div>
                                                <div>
                                                    <button onClick={(e) => vaciar()} style={btn}><img src={iconoGlobal} alt="vaciar" style={img}></img>VACIAR</button>
                                                </div>
                                            </div>
                                            <form className="me-3 ms-3 mt-3" onKeyDown={(e) => { if (e.key === 'Enter') { filtrar() } }}>
                                                <div class="">
                                                    <input
                                                        placeholder="BUSQUEDA GENERAL"
                                                        type="text"
                                                        value={info.info}
                                                        class="inputsDelFiltroSearch"
                                                        onChange={(e) =>
                                                            setInfo((prevState) => ({
                                                                ...prevState,
                                                                info: e.target.value,
                                                            }))
                                                        }
                                                    />
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col">
                                                        <h6 className="subtituloDelFiltro m-0">Asignado</h6>
                                                        <MultiSelect
                                                            options={asignadoInput.sort((a, b) => a.label.localeCompare(b.label))}
                                                            value={info.asignado}
                                                            onChange={(e) => {
                                                                setInfo((prevState) => ({
                                                                    ...prevState,
                                                                    asignado: e,
                                                                }));
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col-6">
                                                        <h6 className="subtituloDelFiltro m-0">Estado</h6>
                                                        <MultiSelect
                                                            className=""
                                                            options={estadoInput}
                                                            value={info.estado}
                                                            onChange={(e) => {
                                                                setInfo((prevState) => ({
                                                                    ...prevState,
                                                                    estado: e,
                                                                }));
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-6">
                                                        <h6 className="subtituloDelFiltro m-0">Nivel</h6>
                                                        <MultiSelect
                                                            className=""
                                                            options={nivelInput}
                                                            value={info.nivel}
                                                            onChange={(e) => {
                                                                setInfo((prevState) => ({
                                                                    ...prevState,
                                                                    nivel: e,
                                                                }));
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col">
                                                        <h6 className="subtituloDelFiltro m-0">Vencimiento</h6>
                                                        <MultiSelect
                                                            className=""
                                                            options={fechaInput}
                                                            value={info.fecha}
                                                            onChange={(e) => {
                                                                setInfo((prevState) => ({
                                                                    ...prevState,
                                                                    fecha: e,
                                                                }));
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col">
                                                        <h6 className="subtituloDelFiltro m-0">Compañias</h6>
                                                        <MultiSelect
                                                            className=""
                                                            options={compInput.sort((a, b) => a.label.localeCompare(b.label))}
                                                            value={info.comp}
                                                            onChange={(e) => {
                                                                setInfo((prevState) => ({
                                                                    ...prevState,
                                                                    comp: e,
                                                                }));
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col">
                                                        <h6 className="subtituloDelFiltro m-0">Productores</h6>
                                                        <MultiSelect
                                                            className=""
                                                            options={proInput.sort((a, b) => a.label.localeCompare(b.label))}
                                                            value={info.pas}
                                                            onChange={(e) => {
                                                                setInfo((prevState) => ({
                                                                    ...prevState,
                                                                    pas: e,
                                                                }));
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="mt-4 center">
                                                    <ReactPaginate
                                                        forcePage={currentPage}
                                                        className='center p-0'
                                                        activeClassName={'item active'}
                                                        breakClassName={'item break-me'}
                                                        breakLabel={'...'}
                                                        marginPagesDisplayed={1}
                                                        onPageChange={handlePageClick}
                                                        pageClassName={'item pagination-page'}
                                                        pageRangeDisplayed={5}
                                                        pageCount={pageCount}
                                                    />
                                                </div>
                                                <h6 className={info.vista ? 'center' : 'center'}>Cantidad de expedientes: {updatedList.length}</h6>
                                            </form>
                                        </div>
                                    </div>
                                </main>}
        </>
    );
}



/* <main className="mainTabla pb-5">
                    {cookies.user ? (
                        <section className="row sectionExpedientesGral">
                            <div className="col-10">
                                {preCarga === true ? (updatedList.length > 0 ?
                                    <div className="ms-2">
                                        <div className="tablaHead row ps-3 m-0">
                                            {sortA.sort === 'NRO ↑' ?
                                                <div class="col p-0 d-flex">
                                                    <p className="tituloTabla ps-2">NRO</p>
                                                    <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'NRO ↓' }))}><i class="bi bi-sort-up" style={iconSort} ></i></button>
                                                </div> : <div className="d-none"></div>}
                                            {sortA.sort === 'NRO ↓' ?
                                                <div class="col p-0 d-flex">
                                                    <p className="tituloTabla ps-2">NRO</p>
                                                    <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'NRO ↑' }))}><i class="bi bi-sort-down" style={iconSort} ></i></button>
                                                </div> : <div className="d-none"></div>}
                                            {sortA.sort !== 'NRO ↑' && sortA.sort !== 'NRO ↓' ?
                                                <div class="col p-0 d-flex">
                                                    <p className="tituloTabla ps-2">NRO</p>
                                                    <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'NRO ↑' }))}><i class="bi bi-filter" style={iconSort} ></i></button>
                                                </div> : <div className="d-none"></div>}


                                            {sortA.sort === 'PATENTE ↑' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla">PATENTE</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'PATENTE ↓ZA' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}
                                            {sortA.sort === 'PATENTE ↓ZA' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla">PATENTE</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'PATENTE ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}
                                            {sortA.sort !== 'PATENTE ↑' && sortA.sort !== 'PATENTE ↓ZA' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla">PATENTE</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'PATENTE ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}

                                            {sortA.sort === 'PAS ↑' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla ps-1">PAS</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'PAS ↓ZA' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}
                                            {sortA.sort === 'PAS ↓ZA' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla ps-1">PAS</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'PAS ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}
                                            {sortA.sort !== 'PAS ↑' && sortA.sort !== 'PAS ↓ZA' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla ps-1">PAS</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'PAS ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}

                                            {sortA.sort === 'FECHA ↑' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla ps-1">F.C ESTADO</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'FECHA ↓ZA' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}
                                            {sortA.sort === 'FECHA ↓ZA' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla ps-1">F.C ESTADO</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'FECHA ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}
                                            {sortA.sort !== 'FECHA ↑' && sortA.sort !== 'FECHA ↓ZA' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla ps-1">F.C ESTADO</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'FECHA ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}

                                            {sortA.sort === 'COMPAÑIA ↑' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla ps-1">CIA</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'COMPAÑIA ↓ZA' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}
                                            {sortA.sort === 'COMPAÑIA ↓ZA' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla ps-1">CIA</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'COMPAÑIA ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}
                                            {sortA.sort !== 'COMPAÑIA ↑' && sortA.sort !== 'COMPAÑIA ↓ZA' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla ps-1">CIA</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'COMPAÑIA ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}


                                            {sortA.sort === 'CLIENTE ↑' ? <div class="col-3 p-0 d-flex">
                                                <p className="tituloTabla ps-1">CLIENTE</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'CLIENTE ↓ZA' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}
                                            {sortA.sort === 'CLIENTE ↓ZA' ? <div class="col-3 p-0 d-flex">
                                                <p className="tituloTabla ps-1">CLIENTE</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'CLIENTE ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}
                                            {sortA.sort !== 'CLIENTE ↑' && sortA.sort !== 'CLIENTE ↓ZA' ? <div class="col-3 p-0 d-flex">
                                                <p className="tituloTabla ps-1">CLIENTE</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'CLIENTE ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}


                                            {sortA.sort === 'VTO ↑' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla ps-1">F VTO</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'VTO ↓ZA' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}
                                            {sortA.sort === 'VTO ↓ZA' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla ps-1">F VTO</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'VTO ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}
                                            {sortA.sort !== 'VTO ↑' && sortA.sort !== 'VTO ↓ZA' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla ps-1">F VTO</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'VTO ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}

                                            {sortA.sort === 'NIVEL ↑' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla ps-1">NIVEL</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'NIVEL ↓' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}
                                            {sortA.sort === 'NIVEL ↓' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla ps-1">NIVEL</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'NIVEL ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}
                                            {sortA.sort !== 'NIVEL ↑' && sortA.sort !== 'NIVEL ↓' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla ps-1">NIVEL</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'NIVEL ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}

                                            {sortA.sort === 'ESTADO ↑' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla ps-1">ESTADO</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'ESTADO ↓ZA' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}
                                            {sortA.sort === 'ESTADO ↓ZA' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla ps-1">ESTADO</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'ESTADO ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}
                                            {sortA.sort !== 'ESTADO ↑' && sortA.sort !== 'ESTADO ↓ZA' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla ps-1">ESTADO</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'ESTADO ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}
                                        </div>
                                        <div class=" mt-3">
                                            <div style={aka}>{updatedList.slice(itemOffset, Number(itemOffset + 20)).map((expediente) => {
                                                return (
                                                    <Expediente
                                                        expediente={expediente}
                                                        key={expediente.id}
                                                    />
                                                );
                                            })}</div>
                                        </div>
                                    </div>
                                    :
                                    <div className="">
                                        <div className="table-responsive scrollbar divTabla">
                                            <div className="tablaHead row ps-3 m-0">
                                                {sortA.sort === 'NRO ↑' ?
                                                    <div class="col p-0 d-flex">
                                                        <p className="tituloTabla ps-2">NRO</p>
                                                        <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'NRO ↓' }))}><i class="bi bi-sort-up" style={iconSort} ></i></button>
                                                    </div> : <div className="d-none"></div>}
                                                {sortA.sort === 'NRO ↓' ?
                                                    <div class="col p-0 d-flex">
                                                        <p className="tituloTabla ps-2">NRO</p>
                                                        <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'NRO ↑' }))}><i class="bi bi-sort-down" style={iconSort} ></i></button>
                                                    </div> : <div className="d-none"></div>}
                                                {sortA.sort !== 'NRO ↑' && sortA.sort !== 'NRO ↓' ?
                                                    <div class="col p-0 d-flex">
                                                        <p className="tituloTabla ps-2">NRO</p>
                                                        <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'NRO ↑' }))}><i class="bi bi-filter" style={iconSort} ></i></button>
                                                    </div> : <div className="d-none"></div>}


                                                {sortA.sort === 'PATENTE ↑' ? <div class="col p-0 d-flex">
                                                    <p className="tituloTabla">PATENTE</p>
                                                    <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'PATENTE ↓ZA' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                                                </div> : <div className="d-none"></div>}
                                                {sortA.sort === 'PATENTE ↓ZA' ? <div class="col p-0 d-flex">
                                                    <p className="tituloTabla">PATENTE</p>
                                                    <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'PATENTE ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                                                </div> : <div className="d-none"></div>}
                                                {sortA.sort !== 'PATENTE ↑' && sortA.sort !== 'PATENTE ↓ZA' ? <div class="col p-0 d-flex">
                                                    <p className="tituloTabla">PATENTE</p>
                                                    <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'PATENTE ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                                                </div> : <div className="d-none"></div>}

                                                {sortA.sort === 'PAS ↑' ? <div class="col p-0 d-flex">
                                                    <p className="tituloTabla ps-1">PAS</p>
                                                    <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'PAS ↓ZA' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                                                </div> : <div className="d-none"></div>}
                                                {sortA.sort === 'PAS ↓ZA' ? <div class="col p-0 d-flex">
                                                    <p className="tituloTabla ps-1">PAS</p>
                                                    <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'PAS ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                                                </div> : <div className="d-none"></div>}
                                                {sortA.sort !== 'PAS ↑' && sortA.sort !== 'PAS ↓ZA' ? <div class="col p-0 d-flex">
                                                    <p className="tituloTabla ps-1">PAS</p>
                                                    <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'PAS ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                                                </div> : <div className="d-none"></div>}

                                                {sortA.sort === 'FECHA ↑' ? <div class="col p-0 d-flex">
                                                    <p className="tituloTabla ps-1">F.C ESTADO</p>
                                                    <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'FECHA ↓ZA' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                                                </div> : <div className="d-none"></div>}
                                                {sortA.sort === 'FECHA ↓ZA' ? <div class="col p-0 d-flex">
                                                    <p className="tituloTabla ps-1">F.C ESTADO</p>
                                                    <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'FECHA ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                                                </div> : <div className="d-none"></div>}
                                                {sortA.sort !== 'FECHA ↑' && sortA.sort !== 'FECHA ↓ZA' ? <div class="col p-0 d-flex">
                                                    <p className="tituloTabla ps-1">F.C ESTADO</p>
                                                    <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'FECHA ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                                                </div> : <div className="d-none"></div>}

                                                {sortA.sort === 'COMPAÑIA ↑' ? <div class="col p-0 d-flex">
                                                    <p className="tituloTabla ps-1">CIA</p>
                                                    <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'COMPAÑIA ↓ZA' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                                                </div> : <div className="d-none"></div>}
                                                {sortA.sort === 'COMPAÑIA ↓ZA' ? <div class="col p-0 d-flex">
                                                    <p className="tituloTabla ps-1">CIA</p>
                                                    <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'COMPAÑIA ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                                                </div> : <div className="d-none"></div>}
                                                {sortA.sort !== 'COMPAÑIA ↑' && sortA.sort !== 'COMPAÑIA ↓ZA' ? <div class="col p-0 d-flex">
                                                    <p className="tituloTabla ps-1">CIA</p>
                                                    <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'COMPAÑIA ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                                                </div> : <div className="d-none"></div>}


                                                {sortA.sort === 'CLIENTE ↑' ? <div class="col-3 p-0 d-flex">
                                                    <p className="tituloTabla ps-1">CLIENTE</p>
                                                    <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'CLIENTE ↓ZA' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                                                </div> : <div className="d-none"></div>}
                                                {sortA.sort === 'CLIENTE ↓ZA' ? <div class="col-3 p-0 d-flex">
                                                    <p className="tituloTabla ps-1">CLIENTE</p>
                                                    <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'CLIENTE ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                                                </div> : <div className="d-none"></div>}
                                                {sortA.sort !== 'CLIENTE ↑' && sortA.sort !== 'CLIENTE ↓ZA' ? <div class="col-3 p-0 d-flex">
                                                    <p className="tituloTabla ps-1">CLIENTE</p>
                                                    <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'CLIENTE ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                                                </div> : <div className="d-none"></div>}


                                                {sortA.sort === 'VTO ↑' ? <div class="col p-0 d-flex">
                                                    <p className="tituloTabla ps-1">F VTO</p>
                                                    <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'VTO ↓ZA' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                                                </div> : <div className="d-none"></div>}
                                                {sortA.sort === 'VTO ↓ZA' ? <div class="col p-0 d-flex">
                                                    <p className="tituloTabla ps-1">F VTO</p>
                                                    <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'VTO ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                                                </div> : <div className="d-none"></div>}
                                                {sortA.sort !== 'VTO ↑' && sortA.sort !== 'VTO ↓ZA' ? <div class="col p-0 d-flex">
                                                    <p className="tituloTabla ps-1">F VTO</p>
                                                    <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'VTO ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                                                </div> : <div className="d-none"></div>}

                                                <div className="col p-0 d-flex"></div>
                                                {sortA.sort === 'ESTADO ↑' ? <div class="col p-0 d-flex">
                                                    <p className="tituloTabla ps-1">ESTADO</p>
                                                    <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'ESTADO ↓ZA' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                                                </div> : <div className="d-none"></div>}
                                                {sortA.sort === 'ESTADO ↓ZA' ? <div class="col p-0 d-flex">
                                                    <p className="tituloTabla ps-1">ESTADO</p>
                                                    <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'ESTADO ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                                                </div> : <div className="d-none"></div>}
                                                {sortA.sort !== 'ESTADO ↑' && sortA.sort !== 'ESTADO ↓ZA' ? <div class="col p-0 d-flex">
                                                    <p className="tituloTabla ps-1">ESTADO</p>
                                                    <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'ESTADO ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                                                </div> : <div className="d-none"></div>}
                                            </div>
                                            <h2 className="center letra mt-5">SIN RESULTADOS</h2>
                                        </div>
                                    </div>
                                ) : <div className="center mt-5 pt-5">
                                    <div class="spinner-border text-primary center" role="status"></div>
                                </div>}
                                <div className="center mt-5">
                                    <Excel data={updatedList} />
                                </div>
                            </div>
                            <div className="col-2 letra">
                                <div className="filtroTicketContainer me-1">
                                    <div className="d-flex ps-3 pt-3">
                                        <div className="">
                                            <button onClick={(e) => filtrar()} style={btn}><img src={iconoFiltrar} alt="filtrar" style={img}></img>FILTRAR</button>
                                        </div>
                                        <div>
                                            <button onClick={(e) => vaciar()} style={btn}><img src={iconoGlobal} alt="vaciar" style={img}></img>VACIAR</button>
                                        </div>
                                    </div>
                                    <form className="me-3 ms-3 mt-3" onKeyDown={(e) => { if (e.key === 'Enter') { filtrar() } }}>
                                        <div class="">
                                            <input
                                                placeholder="BUSQUEDA GENERAL"
                                                type="text"
                                                value={info.info}
                                                class="inputsDelFiltroSearch"
                                                onChange={(e) =>
                                                    setInfo((prevState) => ({
                                                        ...prevState,
                                                        info: e.target.value,
                                                    }))
                                                }
                                            />
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col">
                                                <h6 className="subtituloDelFiltro m-0">Asignado</h6>
                                                <MultiSelect
                                                    options={asignadoInput.sort((a, b) => a.label.localeCompare(b.label))}
                                                    value={info.asignado}
                                                    onChange={(e) => {
                                                        setInfo((prevState) => ({
                                                            ...prevState,
                                                            asignado: e,
                                                        }));
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-6">
                                                <h6 className="subtituloDelFiltro m-0">Estado</h6>
                                                <MultiSelect
                                                    className=""
                                                    options={estadoInput}
                                                    value={info.estado}
                                                    onChange={(e) => {
                                                        setInfo((prevState) => ({
                                                            ...prevState,
                                                            estado: e,
                                                        }));
                                                    }}
                                                />
                                            </div>
                                            <div className="col-6">
                                                <h6 className="subtituloDelFiltro m-0">Nivel</h6>
                                                <MultiSelect
                                                    className=""
                                                    options={nivelInput}
                                                    value={info.nivel}
                                                    onChange={(e) => {
                                                        setInfo((prevState) => ({
                                                            ...prevState,
                                                            nivel: e,
                                                        }));
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col">
                                                <h6 className="subtituloDelFiltro m-0">Vencimiento</h6>
                                                <MultiSelect
                                                    className=""
                                                    options={fechaInput}
                                                    value={info.fecha}
                                                    onChange={(e) => {
                                                        setInfo((prevState) => ({
                                                            ...prevState,
                                                            fecha: e,
                                                        }));
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col">
                                                <h6 className="subtituloDelFiltro m-0">Compañias</h6>
                                                <MultiSelect
                                                    className=""
                                                    options={compInput.sort((a, b) => a.label.localeCompare(b.label))}
                                                    value={info.comp}
                                                    onChange={(e) => {
                                                        setInfo((prevState) => ({
                                                            ...prevState,
                                                            comp: e,
                                                        }));
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col">
                                                <h6 className="subtituloDelFiltro m-0">Productores</h6>
                                                <MultiSelect
                                                    className=""
                                                    options={proInput.sort((a, b) => a.label.localeCompare(b.label))}
                                                    value={info.pas}
                                                    onChange={(e) => {
                                                        setInfo((prevState) => ({
                                                            ...prevState,
                                                            pas: e,
                                                        }));
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="mt-4 center">
                                            <ReactPaginate
                                                forcePage={currentPage}
                                                className='center p-0'
                                                activeClassName={'item active'}
                                                breakClassName={'item break-me'}
                                                breakLabel={'...'}
                                                marginPagesDisplayed={1}
                                                onPageChange={handlePageClick}
                                                pageClassName={'item pagination-page'}
                                                pageRangeDisplayed={5}
                                                pageCount={pageCount}
                                            />
                                        </div>
                                        <h6 className={info.vista ? 'center' : 'center'}>Cantidad de expedientes: {updatedList.length}</h6>
                                    </form>
                                </div>
                            </div>
                        </section>
                    ) : (
                        <h4 className="p-5">Se necesita iniciar sesion</h4>
                    )}
                </main> */