import { useState } from 'react';
import '../index.css'
import axios from 'axios'
import { useCallback } from 'react';
import { useCookies } from 'react-cookie';
import { useMediaQuery } from 'react-responsive'
export default function ListadoDocumentacion({ expediente }) {
    const [cookies] = useCookies(['user']);
    const [check, setChecked] = useState({
        denunciaAdmin: expediente.documentacionDenunciaAdmin === 1 ? true : false,
        dni: expediente.documentacionDni === 1 ? true : false,
        cedula: expediente.documentacionCedulaVerde === 1 ? true : false,
        cartaFranquicia: expediente.documentacionCartaFranquicia === 1 ? true : false,
        cobertura: expediente.documentacionCertificadoCobertura === 1 ? true : false,
        registro: expediente.documentacionRegistro === 1 ? true : false,
        fotos: expediente.documentacionFotos === 1 ? true : false,
        presupuesto: expediente.documentacionPresupuesto === 1 ? true : false,
        cbu: expediente.documentacionCbu === 1 ? true : false
    });
    const [doc, setDocu] = useState({ denunciaAdmin: '', dni: '', cedula: '', cartaFranquicia: '', cobertura: '', registro: '', fotos: '', presupuesto: '', cbu: '' })

    function handleChangeDenunciaAdmin(e) {
        setChecked((prevState) => ({ ...prevState, denunciaAdmin: e.target.checked, }))
        if (!check.denunciaAdmin) {
            setDocu((prevState) => ({
                ...prevState,
                denunciaAdmin: 'Denuncia Administrativa \n',
            }))
        } else if (check.denunciaAdmin) {
            setDocu((prevState) => ({
                ...prevState,
                denunciaAdmin: '',
            }))
        }
    }
    function handleChangeDni(e) {
        setChecked((prevState) => ({ ...prevState, dni: e.target.checked, }))
        if (!check.dni) {
            setDocu((prevState) => ({
                ...prevState,
                dni: 'Dni \n',
            }))
        } else if (check.dni) {
            setDocu((prevState) => ({
                ...prevState,
                dni: '',
            }))
        }
    }
    function handleChangeCedula(e) {
        setChecked((prevState) => ({ ...prevState, cedula: e.target.checked, }))
        if (!check.cedula) {
            setDocu((prevState) => ({
                ...prevState,
                cedula: 'Cedula \n',
            }))
        } else if (check.cedula) {
            setDocu((prevState) => ({
                ...prevState,
                cedula: '',
            }))
        }
    }
    function handleChangeCartaFranquicia(e) {
        setChecked((prevState) => ({ ...prevState, cartaFranquicia: e.target.checked, }))
        if (!check.cartaFranquicia) {
            setDocu((prevState) => ({
                ...prevState,
                cartaFranquicia: 'Carta Franquicia \n',
            }))
        } else if (check.cartaFranquicia) {
            setDocu((prevState) => ({
                ...prevState,
                cartaFranquicia: '',
            }))
        }
    }
    function handleChangeCobertura(e) {
        setChecked((prevState) => ({ ...prevState, cobertura: e.target.checked, }))
        if (!check.cobertura) {
            setDocu((prevState) => ({
                ...prevState,
                cobertura: 'Cobertura \n',
            }))
        } else if (check.cobertura) {
            setDocu((prevState) => ({
                ...prevState,
                cobertura: '',
            }))
        }
    }
    function handleChangeRegistro(e) {
        setChecked((prevState) => ({ ...prevState, registro: e.target.checked, }))
        if (!check.registro) {
            setDocu((prevState) => ({
                ...prevState,
                registro: 'Registro \n',
            }))
        } else if (check.registro) {
            setDocu((prevState) => ({
                ...prevState,
                registro: '',
            }))
        }
    }
    function handleChangeFotos(e) {
        setChecked((prevState) => ({ ...prevState, fotos: e.target.checked, }))
        if (!check.fotos) {
            setDocu((prevState) => ({
                ...prevState,
                fotos: 'Fotos \n',
            }))
        } else if (check.fotos) {
            setDocu((prevState) => ({
                ...prevState,
                fotos: '',
            }))
        }
    }
    function handleChangePresupuesto(e) {
        setChecked((prevState) => ({ ...prevState, presupuesto: e.target.checked, }))
        if (!check.presupuesto) {
            setDocu((prevState) => ({
                ...prevState,
                presupuesto: 'Presupuesto \n',
            }))
        } else if (check.presupuesto) {
            setDocu((prevState) => ({
                ...prevState,
                presupuesto: '',
            }))
        }
    }
    function handleChangeCbu(e) {
        setChecked((prevState) => ({ ...prevState, cbu: e.target.checked, }))
        if (!check.presupuesto) {
            setDocu((prevState) => ({
                ...prevState,
                cbu: 'Cbu \n',
            }))
        } else if (check.presupuesto) {
            setDocu((prevState) => ({
                ...prevState,
                cbu: '',
            }))
        }
    }


    const [textarea, setTextarea] = useState({ textearea: '' })
    const today = new Date()
    const now = today.toLocaleString()


    const solicitarDocumentacion = useCallback(async (e) => {
        e.preventDefault()
        const response = await axios.post(`https://api.tuveunchoque.com.ar/api/mail/ampliacion`, {
            nro: expediente.nro,
            patente: expediente.dominioCliente,
            pas: expediente.productorAsociado,
            denunciaAdmin: check.denunciaAdmin === false ? '-Denuncia Admin' : '',
            dni: check.dni === false ? '-Dni' : '',
            cedula: check.cedula === false ? '-Cedula' : '',
            cartaFranquicia: check.cartaFranquicia === false ? '-Carta Franquicia' : '',
            cobertura: check.cobertura === false ? '-Cobertura' : '',
            registro: check.registro === false  ? '-Registro' : '',
            fotos: check.fotos === false ? '-Fotos' : '',
            presupuesto: check.presupuesto === false ? '-Presupuesto' : '',
            cbu: check.cbu === false ? "-CBU" : '',
            textarea: textarea.textearea ? `${textarea.textearea}` : '',
            sharepoint: expediente.sharepoint
        }).then(async (response) => {
            if (response.status === 200) {
                await axios.post(`https://api.tuveunchoque.com.ar/api/gerencia/detalles/nuevo`,
                    {
                        idExp: expediente.id,
                        cargo: cookies.user.cargo,
                        mensaje: 'Se solicitó documentación',
                        user: cookies.user.chat,
                        estado: expediente.estado,
                        clavado: false,
                        hoy: now,
                        ofrecimiento: false,
                        privado: false
                    }
                )
                setTimeout(async () => {
                    window.location.reload()
                }, 1000);
            }
        })
    }, [expediente, check, textarea]);

    const actualizarDocumentacion = useCallback(async (e) => {
        e.preventDefault()
        const response = await axios.post(`https://api.tuveunchoque.com.ar/api/gerencia/actualizarListadoDocumentacion`, {
            denunciaAdmin: check.denunciaAdmin === true ? 1 : 0,
            dni: check.dni === true ? 1 : 0,
            cedula: check.cedula === true ? 1 : 0,
            cartaFranquicia: check.cartaFranquicia === true ? 1 : 0,
            cobertura: check.cobertura === true ? 1 : 0,
            registro: check.registro === true ? 1 : 0,
            fotos: check.fotos === true ? 1 : 0,
            presupuesto: check.presupuesto === true ? 1 : 0,
            cbu: check.cbu === true ? 1 : 0,
            id: expediente.id
        }).then(async (response) => {
            if (response.status === 200) {
                setTimeout(async () => {
                    window.location.reload()
                }, 1000);
            }
        })
    }, [expediente, check]);

    
    const divInfo = { height: "100px", width: "100%" };

    const isMobile375 = useMediaQuery({ query: '(max-width: 375px)' });
    const isMobile412 = useMediaQuery({ query: '(max-width: 412px)' });
    const isMobile480 = useMediaQuery({ query: '(max-width: 480px)' });

    
    return (
        <>
            <div className='sectionAmpliaciones mb-5 col-6'>
                <div className="divTituloAmpliaciones d-flex align-items-center ps-5">
                    <h3 className='tituloAmpliaciones m-0'>Listado de Documentacion</h3>
                </div>
                <section className='sectionItemsAmpliaciones mt-3'>
                    <div className="row">
                        <div className="col-4">
                            <div className='divCheckboxAmpliaciones form-check form-switch'>
                                <input type="checkbox" className='checkboxAmpliaciones form-check-input' for="flexSwitchCheckDefault" role="switch" onChange={handleChangeDenunciaAdmin} checked={check.denunciaAdmin} />
                                <label htmlFor="" className='labelCheckboxAmpliaciones' id="flexSwitchCheckDefault">Denuncia Admin</label>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className='divCheckboxAmpliaciones form-check form-switch'>
                                <input type="checkbox" className='checkboxAmpliaciones form-check-input' for="flexSwitchCheckDefault" role="switch" onChange={handleChangeDni} checked={check.dni} />
                                <label htmlFor="" className='labelCheckboxAmpliaciones' id="flexSwitchCheckDefault">Dni</label>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className='divCheckboxAmpliaciones form-check form-switch'>
                                <input type="checkbox" className='checkboxAmpliaciones form-check-input' for="flexSwitchCheckDefault" role="switch" onChange={handleChangePresupuesto} checked={check.presupuesto} />
                                <label htmlFor="" className='labelCheckboxAmpliaciones' id="flexSwitchCheckDefault">Presupuesto</label>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <div className='divCheckboxAmpliaciones form-check form-switch'>
                                <input type="checkbox" className='checkboxAmpliaciones form-check-input' for="flexSwitchCheckDefault" role="switch" onChange={handleChangeCedula} checked={check.cedula} />
                                <label htmlFor="" className='labelCheckboxAmpliaciones' id="flexSwitchCheckDefault">Cedula</label>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className='divCheckboxAmpliaciones form-check form-switch'>
                                <input type="checkbox" className='checkboxAmpliaciones form-check-input' for="flexSwitchCheckDefault" role="switch" onChange={handleChangeCartaFranquicia} checked={check.cartaFranquicia} />
                                <label htmlFor="" className='labelCheckboxAmpliaciones' id="flexSwitchCheckDefault">Carta Franquicia</label>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className='divCheckboxAmpliaciones form-check form-switch'>
                                <input type="checkbox" className='checkboxAmpliaciones form-check-input' for="flexSwitchCheckDefault" role="switch" onChange={handleChangeCbu} checked={check.cbu} />
                                <label htmlFor="" className='labelCheckboxAmpliaciones' id="flexSwitchCheckDefault">CBU</label>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <div className='divCheckboxAmpliaciones form-check form-switch'>
                                <input type="checkbox" className='checkboxAmpliaciones form-check-input' for="flexSwitchCheckDefault" role="switch" onChange={handleChangeCobertura} checked={check.cobertura} />
                                <label htmlFor="" className='labelCheckboxAmpliaciones' id="flexSwitchCheckDefault">Cobertura</label>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className='divCheckboxAmpliaciones form-check form-switch'>
                                <input type="checkbox" className='checkboxAmpliaciones form-check-input' for="flexSwitchCheckDefault" role="switch" onChange={handleChangeRegistro} checked={check.registro} />
                                <label htmlFor="" className='labelCheckboxAmpliaciones' id="flexSwitchCheckDefault">Registro</label>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <div className='divCheckboxAmpliaciones form-check form-switch'>
                                <input type="checkbox" className='checkboxAmpliaciones form-check-input' for="flexSwitchCheckDefault" role="switch" onChange={handleChangeFotos} checked={check.fotos} />
                                <label htmlFor="" className='labelCheckboxAmpliaciones' id="flexSwitchCheckDefault">Fotos</label>
                            </div>
                        </div>
                    </div>
                    <div className='row mt-3'>
                        <div className="col-8">
                            <textarea
                                className="form-control"
                                style={divInfo}
                                onChange={(e) =>
                                    setTextarea((prevState) => ({
                                        ...prevState,
                                        textearea: e.target.value,
                                    }))}
                            />
                        </div>
                        <div className="col-4">
                            <button className='botonEnviarAmpliacion w-75 center pbtn' onClick={(e) => solicitarDocumentacion(e)}>SOLICITAR AMPLIACION</button>
                            <button className='btn btn-primary btn-sm w-75 center mt-3' onClick={(e) => actualizarDocumentacion(e)}>ACTUALIZAR</button>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}